<app-top-header class="top-bar-container" bgStyle="solid" screenTitle=' '></app-top-header>
<div class="page-wrapper ">


    <section *ngIf='branches && branches?.length  > 1' class="row mt-2 ml-0 mr-0">

        <div (click)='expandedSections.branches = !expandedSections.branches' class="container">
            <div class="row justify-content-between align-items-center pt-3">

                <div class="">
                    <div class="side-menu">
                        <span class="font-weight-bold"><i class="fa fa-map-marker fa-lg"></i>

                            <span class=" px-2"> {{'Branches locations' | translate}}</span>

                        </span>
                    </div>
                </div>
                <div class="">
                    <span><i class="far fa-lg"
                            [ngClass]="{'fa-angle-up':expandedSections.branches,'fa-angle-down':!expandedSections.branches}"></i></span>
                </div>

            </div>
        </div>

        <hr class="m-0 p-0" />
        <ng-container *ngIf="expandedSections.branches">
            <ng-container *ngFor="let branch of branches">
                <div class="d-block w-100 mt-2">

                    <div class="mx-1 mt-4 w-100"
                        *ngIf='branch?.location?.location?.lat && branch?.location?.location?.lng'>
                        <agm-map [latitude]="branch?.location.location.lat" [longitude]="branch?.location.location.lng"
                            [zoom]='15' style="height: 350px;width:100%">
                            <agm-marker [latitude]="branch?.location.location.lat"
                                [longitude]="branch?.location.location.lng" [label]='({ text: branch?.name.ar })'
                                class='marker-label'>
                            </agm-marker>
                        </agm-map>
                    </div>

                </div>
                <section class="categories-labels-sec py-3 mb-3 bg-white border-bottom-none w-100">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <ul class="categories-labels">
                                    <li>
                                        <a [href]='"tel://" + branch?.location?.contactPhone'
                                            class="btn btn-outline-secondary ">
                                            {{'Call Branch' | translate}}
                                        </a>
                                    </li>
                                    <li>
                                        <button (click)="getDirection(branch)" class="btn btn-outline-secondary">
                                            {{'Get Directions' | translate}}
                                        </button>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </section>
            </ng-container>
        </ng-container>

    </section>

    <section *ngIf='branches && branches?.length  == 1' class="row mt-2 ml-0 mr-0">



        <div class="d-block w-100 mt-2">

            <div class="mx-1 mt-4 w-100"
                *ngIf='branches[0]?.location?.location?.lat && branches[0]?.location?.location?.lng'>
                <agm-map [latitude]="branches[0]?.location.location.lat"
                    [longitude]="branches[0]?.location.location.lng" [zoom]='15' style="height: 350px;width:100%">
                    <agm-marker [latitude]="branches[0]?.location.location.lat"
                        [longitude]="branches[0]?.location.location.lng" [label]='({ text: branches[0]?.name.ar })'
                        class='marker-label'>
                    </agm-marker>
                </agm-map>
            </div>

        </div>
        <section class="categories-labels-sec py-3 mb-3 bg-white border-bottom-none w-100">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <ul class="categories-labels">
                            <li>
                                <a [href]='"tel://" + branches[0]?.location?.contactPhone'
                                    class="btn btn-outline-secondary ">
                                    {{'Call Branch' | translate}}
                                </a>
                            </li>
                            <li>
                                <button (click)="getDirection(branches[0])" class="btn btn-outline-secondary">
                                    {{'Get Directions' | translate}}
                                </button>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>
        </section>


    </section>



    <div style="min-height:65vh" class="content bg-gray">


        <section class="categories-labels-sec py-3 mb-3 bg-white  bg-white border-bottom-none ">
            <div class="container">
                <div style="border-bottom: 2px solid #F5F5F5;" class="row justify-content-start align-items-start py-3">
                    <div class="">
                        <div class="side-menu">
                            <span class="font-weight-bold"><i class="fa fa-clock-o fa-lg "></i>

                                <span class="px-2"> {{'Working Hours' | translate}}</span>
                            </span>
                        </div>
                    </div>

                </div>
            </div>








            <div *ngIf='expandedSections.workingHours' class="container">
                <div *ngFor="let time of truck?.operatingHours"
                    class="row justify-content-between align-items-center pt-3">
                    <div style="font-weight: 500;" class="">
                        <span class="color-black">{{time.day | translate}}:</span>
                    </div>
                    <div class="">
                        <span *ngIf="time.state != '24 hours'" class="color-black">{{time.operatingFrom | iknTime}}
                            {{time.operatingTo | iknTime}}</span>
                        <span *ngIf="time.state == '24 hours'" class="color-black">{{'All Day' | translate}}</span>
                    </div>

                </div>
            </div>
        </section>


        <section class="categories-labels-sec py-3  mb-3 bg-white border-bottom-none">
            <div class="container">
                <div (click)='expandedSections.addToHome = !expandedSections.addToHome'
                    style="border-bottom: 2px solid #F5F5F5 !important;cursor: pointer;"
                    class="row justify-content-between align-items-center pb-3  ">
                    <div class="">
                        <div class="side-menu">

                            <span class="font-weight-bold">
                                <span class="pr-2">
                                    <svg style="width: 15px !important" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path style="fill: gray;"
                                            d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" />
                                    </svg>
                                </span>
                                {{'Add To Home Screen' | translate}}
                            </span>
                        </div>
                    </div>
                    <div class="">
                        <div class="side-menu">
                            <span>
                                <svg *ngIf="!expandedSections.addToHome" style="width: 15px !important"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path style="fill: gray; cursor: pointer;"
                                        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                                </svg>
                                <svg *ngIf="expandedSections.addToHome" style="width: 15px !important"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path style="fill: gray; cursor: pointer;"
                                        d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                                </svg>
                            </span>
                        </div>
                    </div>

                </div>

            </div>
            <div *ngIf="expandedSections.addToHome" class="container">

                <div style="border-bottom: 2px solid #F5F5F5 !important;"
                    class="row justify-content-between align-items-center py-3">
                    <div class="">
                        <span>{{'This website has app functionality. Add it to your home screen to use it in fullscreen
                            and while offline' | translate}}</span>
                    </div>
                </div>
            </div>





            <div *ngIf="expandedSections.addToHome" class="container">
                <div class="row justify-content-between align-items-center pt-3">
                    <div class="">
                        <div><span>{{'1) Press the Share button' | translate}} <span class="px-2">
                                    <svg style="width: 15px !important" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path style="fill: gray;"
                                            d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" />
                                    </svg>
                                </span> {{"on the menu bar below" |translate}}</span></div>
                        <div><span> {{"2) Press Add to Home Screen" |translate}} <span>
                                    <svg style="width: 15px !important" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path style="fill: gray;"
                                            d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM200 344V280H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                                    </svg>
                                </span></span></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="categories-labels-sec py-3   bg-white border-bottom-none">
            <div class="container">
                <div class="row justify-content-center  align-items-center">
                    <div class="">
                        <div class="side-menu">
                            <svg style="width: 20px !important" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                            </svg>
                        </div>
                    </div>


                </div>
            </div>
        </section>

    </div>
</div>