import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ApiService } from "../api.service";
import { CartService } from "../cart.service";
import * as _ from "lodash";
import {
  GeoLocationDto,
  LocalizedField,
  PaymentMethodsLookups,
} from "../models";
import { environment } from "src/environments/environment";
import { Cart } from "../cart";

@Component({
  selector: "app-order-success-popup",
  templateUrl: "./order-success-popup.component.html",
  styleUrls: ["./order-success-popup.component.scss"],
})
export class OrderSuccessPopupComponent implements OnInit {
  @Output()
  trackOrder = new EventEmitter();
  order: any;
  dateOnly: boolean = false;
  isLoading: boolean = false;
  address: LocalizedField = { ar: "", en: "" };
  location: GeoLocationDto = { lat: 0, lng: 0 };
  logo: string = environment.logo;
  cart: Cart;
  paymentMethodsLookup: PaymentMethodsLookups;
  nextCallback: () => void;
  constructor(
    public modal: NgxSmartModalService,
    private api: ApiService,
    private srvCart: CartService
  ) {}

  showOrderSuccessPopup(cb?: () => void, orderId?, orderType?) {
    this.modal.getModal("mOrderSuccess").open();
    this.nextCallback = cb;
    this.isLoading = true;
    this.checkOrderType(orderId, orderType).subscribe((i) => {
      this.dateOnly = i.truck.useDateOnlyForPreOrder;
      this.order = _.cloneDeep(i);

      this.api.viewTruck().subscribe((t) => {
        const truck = t;
        this.paymentMethodsLookup = truck?.truck?.paymentMethodsLookups.find(
          (item) => item?.paymentMethod == this.order?.payment?.paymentMethod
        );
      });

      this.address =
        !orderType || orderType == "Order"
          ? {
              en: this.order.truckLocation.addressLine1,
              ar: this.order.truckLocation.addressLine2,
            }
          : { ar: "", en: "" };
      this.location =
        this.order.truckLocation && this.order.truckLocation.location;

      if (
        this.order.paymentMethod != "cashOnDelivery" &&
        this.order.status != "PendingPayment" &&
        this.order.status != "PaymentRejected"
      )
        this.srvCart.emptyCart();
      this.isLoading = false;
    });
  }

  checkOrderType(orderId, orderType) {
    if (!orderType) orderType = "Order";
    if (orderType == "GroupedOrder")
      return this.api.viewGroupedOrder(Number(orderId));
    else return this.api.viewOrder(Number(orderId));
  }

  ngOnInit() {
    this.api.viewSBOptionsDetails().subscribe((op) => {
      this.logo =
        (op.simpleBrokerOptions && op.simpleBrokerOptions.logo) ||
        environment.logo;
    });
  }

  onDismiss() {
    this.modal.getModal("mOrderSuccess").close();
    this.trackOrder.emit(this.order);
    if (this.nextCallback) this.nextCallback();
    this.nextCallback = undefined;
  }
}
