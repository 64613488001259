import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(area: any[], searchText: string): any[] {
    if (!area) {
      return [];
    }
    if (!searchText) {
      return area;
    }
    searchText = searchText.toLocaleLowerCase();

    return area.filter((it) => {
      if (it.itemInfo)
        return (
          it.itemInfo.title.en.toLocaleLowerCase().includes(searchText) ||
          it.itemInfo.title.ar.toLocaleLowerCase().includes(searchText)
        );

      return (
        it.name.en.toLocaleLowerCase().includes(searchText) ||
        it.name.ar.toLocaleLowerCase().includes(searchText)
      );
    });
  }
}
