<div class="d-flex justify-content-between mb-2" *ngIf="allowShipping">
  <span class="">
    {{ "Country" | translate }}
    <span class="px-1" *ngIf="country" (click)="openCountry()">{{
      country | iknLocalized
    }}</span>
  </span>
  <span class="px-1 color-primary" *ngIf="country" (click)="openCountry()">{{
    "Change Country" | translate
  }}</span>
  <span class="px-1 color-primary" *ngIf="!country" (click)="openCountry()">{{
    "Choose your country" | translate
  }}</span>
</div>

<div class="d-flex justify-content-between mb-2" *ngIf="isShippingMode">
  <span class="">
    {{ "City" | translate }}
    <span class="px-1" *ngIf="country" (click)="openCity()">{{
      cart.orderInfo.pickupAddress.governateName | iknLocalized
    }}</span>
  </span>
  <span class="px-1 color-primary" *ngIf="country" (click)="openCity()">{{
    "Change City" | translate
  }}</span>
  <span class="px-1 color-primary" *ngIf="!country" (click)="openCity()">{{
    "Choose your city" | translate
  }}</span>
</div>

<form #frmAddress="ngForm" [formGroup]="addressForm">
  <div class="input-group">
    <div class="input-wrapper input-border-bottom pb-0">
      <label class="m-0 font-bold font-14">{{ "Full Name" | translate }}</label>
      <input
        type="text"
        (change)="storeAddress()"
        class=" "
        placeholder="{{ 'Enter' | translate }} {{ 'Full Name' | translate }}"
        [(ngModel)]="pickupAddress.contactName"
        formControlName="contactName"
        maxlength="49"
        required
      />
      <div
        *ngIf="submitted && f.contactName.errors && addressType == 1"
        class="invalid-feedback"
      >
        <div *ngIf="f.contactName.errors.required">
          {{ "Name is required" | translate }}
        </div>
      </div>
    </div>
    <!-- {{this.pickupAddress |json}} -->
    <div class="d-flex justify-content-between align-items-center">
      <div class="input-wrapper input-border-bottom pb-0 address-area-sec">
        <label class="m-0 font-bold font-14"
          >{{ "Full Address" | translate }}
          {{ "with notes" | translate }}</label
        >
        <textarea
          id="notes"
          (change)="storeAddress()"
          formControlName="addressLine1"
          [(ngModel)]="pickupAddress.addressLine1"
          rows="3"
          class="address-area"
          placeholder="{{ 'Enter' | translate }} {{
            'Full Address' | translate
          }} {{ 'with notes' | translate }} "
        ></textarea>
        <!-- <input
          type="text"
          (change)="storeAddress()"
          class=" "
          placeholder="{{ 'Enter' | translate }} {{
            'Full Address' | translate
          }} "
          formControlName="addressLine1"
          [(ngModel)]="pickupAddress.addressLine1"
          maxlength="49"
          required
        /> -->
        <div
          *ngIf="submitted && f.addressLine1.errors && addressType == 1"
          class="invalid-feedback"
        >
          <div *ngIf="f.addressLine1.errors.required">
            {{ "Full Address is required" | translate }}
          </div>
        </div>
      </div>

      <div
        *ngIf="!isShippingMode"
        class="map-icon-pos d-flex justify-content-between align-items-center overflow-hidden address-area-map"
        routerLink="/set-location"
      >
        <span>{{ "Or" | translate }}</span>
        <img class="max-width-70" src="../../assets/images/map-icon-.svg" />
      </div>
    </div>

    <div class="input-wrapper input-border-bottom pb-0">
      <label class="m-0 font-bold font-14">{{
        "Phone Number" | translate
      }}</label>
      <ngx-intl-tel-input
        [cssClass]="'phone-input'"
        [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="false"
        [enablePlaceholder]="true"
        [searchCountryPlaceholder]="'Search Country' | translate"
        [searchCountryFlag]="true"
        [searchCountryField]="[SearchCountryField.All]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="selectedCountryCode"
        [phoneValidation]="true"
        [separateDialCode]="true"
        [(ngModel)]="phoneNumber"
        id="phone-id"
        name="contactPhone"
        formControlName="contactPhone"
        (ngModelChange)="validatePhoneNumber()"
      ></ngx-intl-tel-input>

      <div
        *ngIf="
          submitted &&
          f.contactPhone.errors &&
          !validatePhoneNumber() &&
          addressType == 1
        "
        class="invalid-feedback"
      >
        <div>
          {{ "Invalid Phone number" | translate }}
        </div>
      </div>
      <div
        *ngIf="submitted && validatePhoneNumber() && addressType == 1"
        class="invalid-feedback"
      >
        <div *ngIf="f.contactPhone.invalid">
          {{ "Invalid Phone number" | translate }}
        </div>
      </div>
      <!-- <div *ngIf="submitted && f.contactPhone.errors" class="invalid-feedback">
        <div *ngIf="f.contactPhone.invalid">
          {{ "Invalid Phone number" | translate }}
        </div>
      </div> -->
    </div>
    <div class="input-wrapper input-border-bottom pb-0">
      <label class="m-0 font-bold font-14">{{
        "Confirm Phone Number" | translate
      }}</label>
      <ngx-intl-tel-input
        [cssClass]="'phone-input'"
        [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="false"
        [enablePlaceholder]="true"
        [searchCountryPlaceholder]="'Search Country' | translate"
        [searchCountryFlag]="true"
        [searchCountryField]="[SearchCountryField.All]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="selectedCountryCode"
        [phoneValidation]="true"
        [separateDialCode]="true"
        [(ngModel)]="cphoneNumber"
        id="cphone-id"
        name="ccontactPhone"
        formControlName="cphoneNumber"
        (ngModelChange)="validatePhoneNumber2()"
      ></ngx-intl-tel-input>
      <div
        *ngIf="
          submitted &&
          f.cphoneNumber.errors &&
          !validatePhoneNumber2() &&
          addressType == 1
        "
        class="invalid-feedback"
      >
        <div>
          {{ "Invalid Confirm Phone number" | translate }}
        </div>
      </div>
      <div
        *ngIf="submitted && validatePhoneNumber2() && addressType == 1"
        class="invalid-feedback"
      >
        <div *ngIf="f.cphoneNumber.invalid">
          {{ "Invalid Confirm Phone number" | translate }}
        </div>
        <div *ngIf="f.cphoneNumber && f.cphoneNumber?.valid">
          {{ "Invalid Confirm Phone number" | translate }}
        </div>
      </div>
    </div>
    <div class="input-wrapper input-border-bottom pb-0">
      <label class="m-0 font-bold font-14">{{ "Additional Delivery Instructions" | translate }}</label>
      <input
        type="text"
        (change)="storeAddress()"
        class=" "
        placeholder="{{ 'Enter Detailed Description of Delivery Address (Optional)' | translate }}"
        [(ngModel)]="pickupAddress.notes"
        formControlName="notes"
      />
     
    </div>
  </div>
</form>

<app-country-selector
  (countryChange)="onCountryChange($event)"
></app-country-selector>
