<app-top-header
  class="top-bar-container"
  [isServiceScreen]="true"
  bgStyle="solid"
  screenTitle="Time And Date"
></app-top-header>
<div class="h-100 page-max">
  <div class="page-content choose-time-div" *ngIf="!isLoading">
    <div iknLoader [isActive]="isLoading" class="loader-container"></div>
    <div class="wrap">
      <div
        class="d-inline-flex border-top border-bottom w-100 date-time-class scroll-horizontal"
      >
        <div
          class="selectable text-nowrap text-center f-bold px-2 py-2 my-0"
          *ngFor="let date of dates"
          [ngClass]="{ active: selectedDate == date }"
          (click)="setDate(date)"
        >
          <div>{{ date | iknDate : "dm" }}</div>
          <div class="f-bolder mt-1">{{ date | iknDate : "day" }}</div>
        </div>
      </div>
    </div>
    <div class="px-4">
      <div iknLoader [isActive]="isLoadingSlots" class="loader-container"></div>
      <div
        class="mt-4 t-small color-primary"
        *ngIf="cart?.serviceType_ex == 'catering'"
      >
        {{ "Note: Catering is for (3 Hours)" | translate }}
      </div>
      <div class="mt-4" *ngIf="!isLoadingSlots">
        <div
          class="py-2 d-flex align-items-center t-medium f-bold"
          *ngFor="let slot of slots"
        >
          <input
            class="radio"
            [(ngModel)]="selectedSlotId"
            [id]="'t_' + slot.deliveryTime.id"
            [disabled]="!slot.isAvailable"
            (change)="setSlot()"
            type="radio"
            [value]="slot.deliveryTime.id"
          />
          <label
            class="m-0"
            [for]="'t_' + slot.deliveryTime.id"
            [class.text-strike]="!slot.isAvailable"
          >
            <span class="color-black" [class.text-strike]="!slot.isAvailable">
              {{ "Around " | translate }}
              <!-- {{ slot.from | iknDate : "tf" }} - -->
              <!-- {{'to'|translate}} -->
              {{ slot.to | iknDate : "tf" }}
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-1 text-center m-auto d-block w-100 choose-time-btn">
    <div
      style="bottom: unset !important"
      *ngIf="!isLoading && selectedSlot"
      class="page-btn font-bold position-relative pt-3"
    >
      <a
        style="text-transform: capitalize !important"
        class="btn btn-primary bg-primary pull-heading-right t-medium btn-date-time"
        (click)="save()"
      >
        {{ "please choose time" | translate }} -
        {{ selectedSlot?.to | iknDate : "day" }}
        {{ selectedSlot?.to | iknDate : "dm" }}
        <span *ngIf="!dateOnly">
          {{ selectedSlot?.to | iknDate : "tf" }}
          <!-- <span *ngIf="selectedSlot?.to">
            - {{ selectedSlot?.to | iknDate : "tf" }}</span
          > -->
        </span>
      </a>
      <div class="clearfix"></div>
    </div>
  </div>
</div>

<!-- <app-footer></app-footer> -->
