import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../api.service";
import { DefaultIntl } from "../DatePickerProviders";
import { SBOptions, TruckSBOptionsWithDetails } from "../models";
import { UtilsService } from "../utils.service";

@Component({
  selector: "app-top-header",
  templateUrl: "./top-header.component.html",
  styleUrls: ["./top-header.component.scss"],
})
export class TopHeaderComponent implements OnInit, OnDestroy {
  @Input()
  bgStyle: string = "transparent";
  @Input()
  hideLogo: boolean = false;
  @Input()
  screenTitle = "";
  @Input()
  handleScroll = false;
  info: TruckSBOptionsWithDetails;
  expanded: boolean = false;
  @Input() isCategoryItemsScreen?: boolean = false;
  @Input() isServiceScreen?: boolean = false;

  private clickHandler;
  private scrollHandler;

  constructor(
    private translate: TranslateService,
    private api: ApiService,
    private utils: UtilsService
  ) {
    this.clickHandler = this._onDocumentClick.bind(this);
    this.scrollHandler = this._onScroll.bind(this);
    $(document).click(this.clickHandler);
  }
  private _onScroll(e: any) {
    let posY = $(document).scrollTop();
    if (posY > 240) {
      this.bgStyle = "solid";
      this.hideLogo = false;
    } else {
      this.bgStyle = "transparent";
      this.hideLogo = true;
    }
  }
  private _onDocumentClick(e: any) {
    let el = $(e.target);
    if (el.hasClass("collapse-sub-menu")) {
      return;
    }

    this.expanded = false;
  }
  ngOnInit() {
    this.api.viewSBOptionsDetails().subscribe((d) => {
      this.info = d;
    });
    if (this.handleScroll) $(document).scroll(this.scrollHandler);
  }
  ngOnDestroy() {
    $(document).unbind("click", this.clickHandler);
    $(document).unbind("scroll", this.scrollHandler);
  }

  goBack() {
    this.utils.goBack();
  }
  logout() {
    this.api.logout().subscribe((d) => {
      this.api.clearAuth();
    });
  }
  isLoggedIn() {
    return this.api.isLoggedIn;
  }
  toggleLang(forceLang?: string) {
    let lang = this.translate.currentLang == "ar" ? "en" : "ar";
    if (forceLang) lang = forceLang;
    this.translate.use(lang);
    localStorage.setItem("user_lang", lang);
    $("body").removeClass("lang-ar lang-en").addClass(`lang-${lang}`);
    DefaultIntl.langUpdated(this.translate);
  }
}
