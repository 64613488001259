<!-- END END END -->
<app-top-header class="top-bar-container" bgStyle="solid"></app-top-header>
<ng-container>
  <div class="row justify-content-start align-items-start bg-gray-2">
    <div class="col-auto">
      <div class="side-menu details-header py-2">
        <i
          (click)="goBack()"
          class="far fa-angle-left fa-lg cursor-pointer"
        ></i>

        <span class="font-weight-bold font-14 px-2 color-black">
          {{ "Checkout" | translate }}
        </span>
      </div>
    </div>
  </div>
  <!-- <div class="container mx-0 px-0 space-between"></div> -->

  <div iknLoader [isActive]="isLoading" class="loader-container"></div>
  <!-- Time and date -->

  <!-- Area - Service or choose -->

  <!-- Choose payment type -->
  <div *ngIf="paymentMethods?.length" class="container pt-3">
    <div class="row pb-2">
      <h5 class="item-title my-0">
        <span class="font-14 color-black">
          {{ "Choose Payment Method" | translate }}
        </span>
      </h5>
    </div>

    <form [formGroup]="paymentForm" #frmPayment="ngForm">
      <ng-container *ngFor="let pm of paymentMethods">
        <div
          *ngIf="!isShipping || pm.paymentMethod != 'cashOnDelivery'"
          class="radios pb-1"
        >
          <div class="custom-radio">
            <input
              (change)="storePaymentMethod()"
              class="cursor-pointer"
              id="styled-radio-4{{ pm.paymentMethod }}"
              type="radio"
              [(ngModel)]="paymentMethod"
              [value]="pm.paymentMethod"
              [checked]="paymentMethod == pm.paymentMethod"
              formControlName="paymentMethod"
            />
            <label
              for="styled-radio-4{{ pm.paymentMethod }}"
              class="cursor-pointer radio-label-st font-14"
              >{{ pm.name | iknLocalized }}
            </label>
          </div>
          <!-- <div class="custom-radio">
            <input
              class="radio cursor-pointer"
              [(ngModel)]="paymentMethod"
              (change)="storePaymentMethod()"
              id="styled-radio-4{{ pm.paymentMethod }}"
              type="radio"
              [value]="pm.paymentMethod"
              formControlName="paymentMethod"
            />
            <label
              for="styled-radio-4{{ pm.paymentMethod }}"
              class="cursor-pointer radio-label-st font-14"
              >{{ pm.name | iknLocalized }}
            </label>
          </div> -->
        </div>
      </ng-container>
    </form>
  </div>

  <!-- Delivery Address   -->

  <div class="container">
    <div class="row pb-2">
      <h5 class="item-title my-0">
        <span class="font-14 color-black">
          {{ "Contact Information" | translate }}
        </span>
      </h5>
    </div>

    <form [formGroup]="contactForm" #frmContact="ngForm">
      <div class="input-group">
        <div class="input-wrapper input-border-bottom">
          <label class="m-0 font-bold font-14">{{
            "Full Name" | translate
          }}</label>
          <input
            type="text"
            (change)="storeAddress()"
            class=" "
            placeholder="{{ 'Enter' | translate }}  {{
              'Full Name' | translate
            }}"
            [(ngModel)]="pickupAddress.contactName"
            formControlName="contactName"
            maxlength="49"
            required
            [ngClass]="{ 'is-invalid': submitted && fc.contactName.errors }"
          />

          <div
            *ngIf="submitted && fc.contactName.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fc.contactName.errors.required">
              {{ "Name is required" | translate }}
            </div>
          </div>
        </div>

        <div class="input-wrapper input-border-bottom">
          <label class="m-0 font-bold font-14">{{
            "Phone Number" | translate
          }}</label>
          <ngx-intl-tel-input
            [cssClass]="'phone-input'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="false"
            [enablePlaceholder]="true"
            [searchCountryPlaceholder]="'Search Country' | translate"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.All]"
            [selectFirstCountry]="true"
            [selectedCountryISO]="selectedCountryCode"
            [phoneValidation]="true"
            [separateDialCode]="true"
            [(ngModel)]="phoneNumber"
            id="phone-id"
            name="contactPhone"
            formControlName="contactPhone"
            (ngModelChange)="validatePhoneNumber()"
            [ngClass]="{ 'is-invalid': validatePhoneNumber() }"
          ></ngx-intl-tel-input>
          <div
            *ngIf="submitted && fc.contactPhone?.errors?.required"
            class="invalid-feedback"
          >
            <div>
              {{ "Enter Phone Number" | translate }}
            </div>
          </div>
          <div
            *ngIf="submitted && validatePhoneNumber()"
            class="invalid-feedback"
          >
            <div *ngIf="fc?.contactPhone?.invalid">
              {{ "Invalid Phone Number" | translate }}
            </div>
          </div>
        </div>

        <div *ngIf="allowEmail" class="input-wrapper input-border-bottom">
          <label class="m-0 font-bold font-14">{{ "Email" | translate }}</label>
          <input
            type="text"
            (change)="storeAddress()"
            class=" "
            placeholder=" {{ 'Enter' | translate }} {{ 'E-mail' | translate }}"
            [(ngModel)]="pickupAddress.contactEmail"
            formControlName="contactEmail"
            [ngClass]="{
              'is-invalid': submitted && fc.contactEmail.errors
            }"
          />

          <div
            *ngIf="submitted && fc.contactEmail.errors"
            class="invalid-feedback"
          >
            <div *ngIf="fc.contactEmail.errors.required">
              {{ "Name is required" | translate }}
            </div>
          </div>
        </div>
        <div
          *ngIf="allowMembershipNumber"
          class="input-wrapper input-border-bottom"
        >
          <label class="m-0 font-bold font-14">{{
            "Membership Number" | translate
          }}</label>
          <input
            type="text"
            class=" "
            placeholder="{{ 'Enter' | translate }}{{
              'Membership Number' | translate
            }}"
            (change)="storeAddress()"
            formControlName="membershipNo"
            [(ngModel)]="cart.orderInfo.membershipNo"
          />
        </div>
      </div>
      <div
        class="input-wrapper no-border"
        *ngIf="cart.serviceType_ex == 'pickup'"
      >
        <label class="m-0 font-bold font-14">{{
          "Car model and color" | translate
        }}</label>
        <input
          [ngModelOptions]="{ standalone: true }"
          type="text"
          (change)="storeAddress()"
          class=""
          placeholder="{{ 'Enter' | translate }} {{
            'Car model and color' | translate
          }}"
          [(ngModel)]="cart.orderInfo.fileNumber"
        />
      </div>
    </form>

    <div class="row pb-2">
      <h5 [ngSwitch]="cart.orderInfo.serviceType" class="item-title my-0">
        <span *ngSwitchDefault class="font-14 color-black">
          {{ "Enter your delivery address" | translate }}
        </span>
        <span *ngSwitchCase="'pickup'" class="font-14 color-black">
          {{ "Pickup Address" | translate }}
        </span>
        <span *ngSwitchCase="'catering'" class="font-14 color-black">
          "Catering Address" | translate
        </span>
        <!-- <span>
          {{ pickupAddress?.title }}
        </span> -->
      </h5>
    </div>

    <div class="row pb-2">
      <h5 class="item-title my-0">
        <span class="font-14 color-black">
          {{ "Branch" | translate }}
        </span>
      </h5>
    </div>
    <div class="row pb-2">
      <h5 class="item-title my-0">
        <span class="font-14 color-black">
          {{ cart.$branch?.name | iknLocalized }}
        </span>
      </h5>
    </div>

    <div class="mt-3">
      <div
        class="f-boldest t-normal py-2 color-primary"
        [ngSwitch]="cart.orderInfo.serviceType"
      >
        <span *ngSwitchDefault>{{ "Delivery Address" | translate }}</span>
        <span *ngSwitchCase="'pickup'">{{ "Pickup Address" | translate }}</span>
        <span *ngSwitchCase="'catering'">{{
          "Catering Address" | translate
        }}</span>
      </div>
      <div
        class="mt-2 t-normal pb-2"
        *ngIf="cart.orderInfo.serviceType != 'pickup'"
      >
        <div class="py-0">
          <span class="f-bold">{{ "Address Title" | translate }}: </span>
          <span>
            {{ pickupAddress?.title }}
          </span>
        </div>
      </div>
      <!-- <div
        class="mt-2 t-normal pb-2"
        *ngIf="cart.orderInfo.serviceType == 'pickup'"
      >
        <div class="py-2">
          <span class="f-bold">{{ "Address" | translate }}: </span>
          <span>
            {{ cart.$branch?.location.address | iknLocalized }}
            <a
              target="_blank"
              href="http://maps.google.com/?q={{
                cart.$branch?.location.location.lat
              }}, {{ cart.$branch?.location.location.lng }}"
            >
              <i class="fa fa-lg fas fa-map-marker text-primary"></i>
            </a>
          </span>
        </div>
      </div> -->

      <div class="d-flex justify-content-between align-items-center">
        <div class="input-wrapper input-border-bottom pb-0">
          <label class="m-0 font-bold font-14">{{
            "Address" | translate
          }}</label>
          <input
            type="text"
            class="disableForm"
            value="{{ cart.$branch?.location.address | iknLocalized }}"
          />
        </div>

        <div
          class="map-icon-pos d-flex justify-content-between align-items-center"
        >
          <span>{{ "Or" | translate }}</span>
          <a
            target="_blank"
            href="http://maps.google.com/?q={{
              cart.$branch?.location.location.lat
            }}, {{ cart.$branch?.location.location.lng }}"
          >
            <img src="../../assets/images/map-icon-.svg" />
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-5">
    <div class="row justify-content-center align-items-center mb-5">
      <div class="col-auto">
        <div class="f-boldest t-large" [@slideInUpOnEnter]>
          <a
            (click)="submitOrder()"
            class="btn btn-cart page-btn-checkout px-4"
          >
            <span *ngIf="isOpen !== undefined">
              <span
                class="font-weight-bold"
                *ngIf="isOpen || cart.orderInfo.pickupTime"
                >{{ "Complete the request" | translate }}</span
              >
              <span
                class="font-weight-bold"
                *ngIf="isOpen === false && !cart.orderInfo.pickupTime"
              >
                {{ "submit_closed" | translate }}
                {{ "operating_hours_warning" | translate : operatingHours }}
              </span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <form
    [formGroup]="termsForm"
    #frmTerms="ngForm"
    class="mt-4 t-normal"
    *ngIf="allowTerms"
  >
    <input
      class="d-none"
      id="accept_terms"
      type="checkbox"
      formControlName="acceptTerms"
      [(ngModel)]="acceptTerms"
    />
    <label
      for="accept_terms"
      class="t-normal font-bold font-14 color-primary"
      *ngIf="allowTerms"
    >
      {{ "I agree to" | translate }}
      <a [routerLink]="['/terms']" class="f-boldest color-primary">{{
        "the terms and conditions" | translate
      }}</a>

      <!-- {{'srv_' + cart.orderInfo.serviceType + '_service' | translate}} -->
    </label>
    <div *ngIf="submitted && ft.acceptTerms.errors" class="invalid-feedback">
      <div *ngIf="ft.acceptTerms.invalid">
        {{ "You must agree to terms and conditions" | translate }}
      </div>
    </div>
  </form>
</ng-container>
<div>
  <ngx-smart-modal
    #mconfirmPhonenumber
    identifier="mphoneValidator"
    [dismissable]="false"
  >
    <div class="modal-header"></div>
    <div class="modal-body">
      <div class="popap">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="!isverificationCodeValid"
        >
          {{ "Verification code not valid" | translate }}
        </div>
        <br />
        <br />
        <h3>{{ "OTP Verification" | translate }}</h3>
        <p>
          {{ "Please enter the verification code sent" | translate }} <br />{{
            "To" | translate
          }}
          {{ phoneNumber?.internationalNumber }}
        </p>
        <span id="timer">{{ recordingTimer }}</span
        ><br />
        <div class="codeinputcontainer">
          <input
            class="form-control codeinput"
            type="text"
            name="code"
            required
            (ngModelChange)="changeCode()"
            [(ngModel)]="verificationCode"
          />
        </div>
        <p>
          {{ "Didnt receive OTP" | translate }}
          <a class="resend" (click)="sendVerificationCode()"
            >{{ "Resend OTP" | translate }}
          </a>
        </p>
        <button class="verifycodebtn" (click)="verifyThePhoneCode()">
          {{ "Verify" | translate }}
        </button>
      </div>
    </div>
    <div class="modal-footer"></div>
  </ngx-smart-modal>
</div>
