import { Component, Input } from '@angular/core';
import { CategoryView } from '../models';
import * as _ from 'lodash'
import { Router } from '@angular/router';
import { NgIfContext } from '@angular/common';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { UtilsService } from '../utils.service';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-base-category-card',
  templateUrl: './base-category-big-card.component.html',
  styleUrls: ['./base-category-big-card.component.scss']
})
export class BaseCategoryBigCardComponent {

  @Input('category') cat: CategoryView
  @Input() showItems?:boolean;
  @Input() lastCategory?:boolean;

  cart: Cart
  constructor(private router: Router, private srvCart: CartService, private utils: UtilsService , private api: ApiService,) {
    this.cart = srvCart.getCart();

  }
  onCategoryClick(cat: any) {
    if (cat?.categoryInfo?.subPic) {
      window.open(cat?.categoryInfo?.subPic, "_self")
      return;
    }
    else
    this.utils.CheckStateAndContinue(this.cart.$truck.lastKnowState, () => {
      if (cat.categoryInfo.id == -100) //catering
      {
        //this.srvCart.setServiceType('catering', 'catering');
        this.router.navigate(['catering-area', { returnTo: '/categories/' + cat.categoryInfo.id + '/items' }], {
        });
        return;
      }
      if (cat.subCategories && cat.subCategories.length) this.router.navigate(['/sub-category/' + cat.id]);
      else this.router.navigate(['/categories/' + cat.categoryInfo.id + '/items']);
    });
  }
  checkCategory() {
    if (this.cat.categoryInfo.id == -1) { // Sold Items Category
      return this.api.getTopSoldItems()
    } else if (this.cat.categoryInfo.id == -2) { // Recently Added Items Category
      return this.api.getTopRecentlyAddedItems()
    } else
      return this.api.viewCategory(this.cat.categoryInfo.id);
  }
}
