<div
  class="page-btn font-extraBold"
  *ngIf="cart?.cartItems?.length"
  routerLink="/cart"
>
  <a
    href="javascript:{}"
    class="btn btn-primary d-flex justify-content-between align-items-center"
  >
    <div>{{ cart?.$totalItemsQty }}</div>
    <div class="flex-grow-1 text-center">{{ "Review Cart" | translate }}</div>
    <div>
      {{ cart?.$subTotal | iknCurrency }}
      {{ cart?.$truck?.defaultCountry?.currencyCode | iknLocalized }}
    </div>
  </a>
</div>

<app-top-header
  class="top-bar-container"
  [bgStyle]="'solid'"
  [screenTitle]="(category?.categoryInfo?.title | iknLocalized) || ' '"
></app-top-header>

<br />

<div class="container">
  <div class="rpw">
    <div class="m-t-15"></div>
    <div iknLoader [isActive]="isLoading" class="loader-container"></div>
    <div class="card-column" *ngFor="let cat of categories">
      <div
        class="menu-card c-pointer"
        routerLink="/categories/{{ cat?.categoryInfo.id }}/items"
      >
        <div class="img hide-image">
          <img
            class="subcat-img"
            [src]="cat?.categoryInfo?.mainPic || 'assets/images/logo.png'"
            alt=""
          />
        </div>
        <p class="card-title no-margin">
          {{ cat?.categoryInfo?.title | iknLocalized }}
        </p>
        <div class="card-main-items">
          <div class="btn btn-outline-secondary p-0 px-2 position-relative">
            <span class="no-p-m sub-item-text"
              >{{ cat?.items?.length }} {{ "Items" | translate }}</span
            >
            <!-- <p class="no-p-m"></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-btn font-bold" *ngIf="!isLoading && cart?.cartItems?.length">
  <a class="btn btn-primary bg-primary btn-cart" routerLink="/cart">
    <span>{{ "Checkout" | translate }}</span>
  </a>
</div>
<!-- <app-footer></app-footer>
    <br />
    <br /> -->
