<ng-template #template>

  <div class="modal-body">
    <i class="icon-close lose pull-right" (click)="modalRef.hide()">

    </i>
    <owl-carousel-o [options]="topSlidersOptions" *ngIf="images?.length > 0">
      <ng-template carouselSlide *ngFor="let item of images; let i = index">
        <img [src]="item.url || '/assets/images/logo.png'" alt="" class=" " />
      </ng-template>
    </owl-carousel-o>
  </div>
</ng-template>