import * as _ from "lodash";
import * as M from "./models";
import { environment } from "src/environments/environment";
function round(num) {
  return Math.round(num * 1000 + Number.EPSILON) / 1000;
}
export class Cart {
  $truck: M.TruckInfo;
  $branch: M.TruckBranch;
  serviceType_ex: string = "";
  orderInfo: M.OrderInfo = {
    serviceType: "delivery",
    paymentMethod: "",
    pickupAddress: {
      location: {},
    },
  };
  contactInfo: M.ContactInfo;
  shippingCharge: number;
  $discount: number;
  get $totalItemsQty(): number {
    return round(_.sumBy(this.cartItems, "qty")) || 0;
  }
  get $collectorCharge(): number {
    if (!this.$truck || !this.$truck.collectorChargePercentage) return 0;
    return round(
      this.$subTotal * (this.$truck.collectorChargePercentage / 100)
    );
  }
  get $deliveryCharge(): number {
    let charge = 0;
    if (this.orderInfo.serviceType == "pickup") return 0;
    if (
      environment.allow_shipping &&
      this.orderInfo &&
      this.orderInfo.pickupAddress &&
      this.orderInfo.pickupAddress.countryId !=
        ((this.$truck &&
          this.$truck.defaultCountry &&
          this.$truck.defaultCountry.id) ||
          environment.default_country)
    )
      return 0;
    //truck not loaded, we returned whatever stored before
    if (!this.$truck) return this.orderInfo.deliveryCharge;
    //free delivery invoice
    if (
      this.$truck.freeDeliveryMinInvoice > 0 &&
      this.$subTotal >= this.$truck.freeDeliveryMinInvoice
    )
      return 0;
    //all items free delivery
    if (_.every(this.cartItems, (i) => i.$item.isFreeDelivery)) return 0;

    var area = _.find(
      this.$truck.areas,
      (c) => c.id == this.orderInfo.pickupAddress.areaId
    );

    charge =
      (area && area.deliveryCharge) ||
      (this.$truck && this.$truck.deliveryCharge) ||
      0;

    //add charge from category info
    let that = this;

    let baseCategories = _(this.cartItems)
      .map((ci) => that.baseCategory(ci.$item.id))
      .uniq()
      .value();
    let extraCharge = _.sumBy(
      baseCategories,
      (d) => d.categoryInfo.extraDeliveryCharge || 0
    );
    this.orderInfo.deliveryCharge = charge;
    return charge + extraCharge;
  }

  public baseCategory(itemId): any {
    let item = _.find(
      this.$truck.menu.allItems,
      (d) => d.itemInfo.id == itemId
    );
    if (!item)
      return {
        categoryInfo: {
          extraDeliveryCharge: 0,
        },
      };
    let itemCatId = item.catId;

    let itemCategory = _.find(
      this.$truck.menu.allCategories,
      (d) => d.categoryInfo.id == itemCatId
    );
    if (itemCategory.parentCategoryId) {
      itemCategory = _.find(
        this.$truck.menu.allCategories,
        (d) => d.categoryInfo.id == itemCategory.parentCategoryId
      );
    }
    return itemCategory;
  }
  cartItems: Array<M.CartItem> = [];
  get $subTotal(): number {
    return round(_.sumBy(this.cartItems, "$total")) || 0;
  }
  get $total(): number {
    let res = 0;
    if (this.orderInfo.serviceType === "delivery")
      res = round(
        this.$subTotal +
          this.$deliveryCharge +
          (this.shippingCharge || 0) +
          (this.$collectorCharge || 0)
      );
    else res = round(this.$subTotal + (this.$collectorCharge || 0));
    res = round(res - (this.$discount || 0));
    if (res < 0) res = 0;
    return res;
  }

  checkMaxQty(c: M.CartItem) {
    let foundItem = _.find(
      this.$truck.menu.allItems,
      (i) => i.itemInfo.id == c.$item.id
    );
    if (foundItem)
      return foundItem.itemInfo.maxQty && c.qty > foundItem.itemInfo.maxQty;
    return false;
  }
}
