import { Component, OnInit } from '@angular/core';
import { LocalizedField } from '../models';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  isLoading:boolean = false;
  terms: LocalizedField;

  constructor(apiServce : ApiService , public utils:UtilsService) {
    this.isLoading = true; 
    apiServce.viewSBOptions().subscribe(res => {
      this.isLoading = false; 
      if(res.simpleBrokerOptions)
        this.terms = res.simpleBrokerOptions.terms;
    });
  }

  ngOnInit() {
  }

}
