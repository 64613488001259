import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CartService } from '../cart.service';
import { UtilsService } from '../utils.service';
import { Cart } from '../cart';
import { ApiService } from '../api.service';
import * as _ from 'lodash'
import { TranslateService } from '@ngx-translate/core';
import { TruckSBOptionsWithDetails } from '../models';

@Component({
  selector: 'app-ask-for-membership',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './ask-for-membership.component.html',
  styleUrls: ['./ask-for-membership.component.scss']
})
export class AskForMembershipComponent implements OnInit {
 
  cart:Cart;
  askForMembership: boolean = false;
  isLoading:boolean = false;
  sbOptions: TruckSBOptionsWithDetails;
  public memberInfo =  {
    civilId: '',
    fileNumber: '',
    membershipNo: ''
  }
  nextCallback:()=>void
  constructor(public modal:NgxSmartModalService,
    private trans: TranslateService,
    private cartService: CartService,private utils:UtilsService, private api : ApiService) { 

    utils.setAskForMembershipModal(this);
  }
  showModal(cb?:()=>void) {
    if(!this.askForMembership)
    {
      if(cb)
        cb();
    } else{
      this.modal.getModal('mAskForMembership').open();
      this.nextCallback = cb;
    }
  }

  ngOnInit() {
    this.cart = this.cartService.getCart();
    
    let that = this;
    this.api.viewSBOptionsDetails().subscribe(d=>{
      this.sbOptions = d;

      this.askForMembership = d.simpleBrokerOptions && d.simpleBrokerOptions.forceUsingMembership;
      this.askForMembership =
      this.askForMembership && (!this.cart.orderInfo.civilId || !this.cart.orderInfo.fileNumber);
    });    
  }

  onSave(){
    if(this.isLoading)
      return;
    if(!this.memberInfo.civilId || !this.memberInfo.fileNumber){
      this.utils.toaster.error(this.trans.instant('MEMBER_NOT_FOUND'));
      return;
    }
    
    //let's just do that
    this.isLoading = true;
    this.api.getMembershipInfo(this.memberInfo).subscribe((member)=>{
      this.isLoading = false;
      if(!member){
        this.utils.toaster.error(this.trans.instant('MEMBER_NOT_FOUND'));
        return;  
      }
      if(member.customerName)
      {
        this.cart.orderInfo.pickupAddress.contactName = member.customerName;
        this.cart.orderInfo.contactName = member.customerName;
      }  
      if(member.phone)
        {
          this.cart.orderInfo.pickupAddress.contactPhone = member.phone;
          this.cart.orderInfo.contactPhone = member.phone;
        }
      this.cart.orderInfo.civilId = member.civilId;
      this.cart.orderInfo.fileNumber = member.fileNumber;
      this.cartService.saveCart();
      this.askForMembership = false;
      
      this.modal.getModal('mAskForMembership').close();
      if(this.nextCallback)
        this.nextCallback();
      this.nextCallback = undefined;

    }, err=>{
      this.utils.toaster.error(this.trans.instant('MEMBER_NOT_FOUND'));
      this.isLoading = false;
    });
    
  }

}
