import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { Component } from '@angular/core';
@Component({
  selector: 'app-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss']
})
export class CartIconComponent {

  flag = true;
  isLoading: boolean = false;
  cart: Cart

  constructor(public srvCart: CartService) {
    //this.cart = srvCart.getCart();
  }

  getCart(){
    return this.srvCart.getCart();
  }
}
