import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CategoryView } from '../models';

@Component({
  selector: 'app-extra-category-alert',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './extra-category-alert.component.html',
  styleUrls: ['./extra-category-alert.component.scss']
})
export class ExtraCategoryAlertComponent implements OnInit {
  @Input()
  deliveryCharge:any;
  @Input()
  deliveryTime:any;
  closeResult: string;
  results = [];
  nextCallback:()=>void
  constructor(public modal:NgxSmartModalService) { }

  selectExtraCatAlert(cb?:()=>void,cat?:CategoryView) {
    this.modal.getModal('mExtraCatAlert').open();
    this.nextCallback = cb;
    if(cat){
      this.deliveryCharge = cat.categoryInfo.extraDeliveryCharge;
      this.deliveryTime = cat.categoryInfo.extraDeliveryTimeMinutes;
    }
  }

  ngOnInit() {
  }

  onOk(){
    this.modal.getModal('mExtraCatAlert').close();
    if(this.nextCallback)
      this.nextCallback();
    this.nextCallback = undefined;
  }
}
