<form [formGroup]="loginForm">
  <div>
    <h3 class="m-0 w-100 f-boldest">
      {{ "Username" | translate }}
    </h3>
  </div>
  <div class="input-wrapper d-flex align-items-center justify-content-between">
    <div class="flex-grow-1">
      <input
        type="text"
        [(ngModel)]="username"
        class=""
        placeholder="{{ 'Enter username or phone number' | translate }}"
        formControlName="username"
      />
    </div>
  </div>
  <div class="mt-4">
    <h3 class="m-0 w-100 f-boldest">
      {{ "Password" | translate }}
    </h3>
  </div>
  <div class="input-wrapper d-flex align-items-center justify-content-between">
    <div class="flex-grow-1">
      <input
        type="password"
        [(ngModel)]="password"
        class=""
        placeholder="{{ 'Enter your password' | translate }}"
        formControlName="password"
      />
    </div>
  </div>
  <div
    class="mt-4 d-flex flex-column justify-content-between align-items-center"
  >
    <button
      class="btn btn-primary d-flex justify-content-between btn-bg-seconday align-items-center ng-tns-c94-1"
      (click)="login()"
    >
      {{ "Login" | translate }}
      <div iknLoader [isActive]="isLoading" class="loader-container"></div>
    </button>
    <a
      class="py-4"
      [routerLink]="['/forget-password', { returnTo: returnTo }]"
      >{{ "Forget Password" | translate }}</a
    >
    <div class="text-center">
      {{ "Don't have account?" | translate }}
      <a [routerLink]="['/register', { returnTo: returnTo }]">{{
        "Create your account now" | translate
      }}</a>
    </div>
  </div>
</form>
