import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-catering",
  templateUrl: "./catering.component.html",
  styleUrls: ["./catering.component.css"],
})
export class CateringComponent implements OnInit {
  src = "/assets/images/menu.pdf";
  constructor() {}

  ngOnInit(): void {}
  download() {
    window.open(this.src, "_blank");
  }
}
