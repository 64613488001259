import { Component, OnInit } from "@angular/core";
import {
  ApiResult,
  LocalizedField,
  SBOptions,
  TruckBranch,
  TruckInfo,
} from "../models";
import { ApiService } from "../api.service";
import { UtilsService } from "../utils.service";

@Component({
  selector: "app-truck-info",
  templateUrl: "./truck-info.component.html",
  styleUrls: ["./truck-info.component.scss"],
})
export class TruckInfoComponent implements OnInit {
  truck: TruckInfo;
  branches: TruckBranch[];
  sb: SBOptions;
  expandedSections: any = {};
  showTerms = false;
  constructor(private api: ApiService, public utils: UtilsService) {}

  ngOnInit() {
    this.api.viewTruck().subscribe((t) => {
      this.truck = t.truck;
    });
    this.api.viewSBOptionsDetails().subscribe((d) => {
      this.sb = d && d.simpleBrokerOptions;
      if (this.sb.terms.ar && this.sb.terms.en) this.showTerms = true;

      if (!d.hasMultipleBranches) {
        this.api.ViewTruckStatus().subscribe((st) => {
          this.branches = [
            {
              id: -1,
              name: {
                ar: "الفرع الرئيسي",
                en: "Main Branch",
              },
              location: {
                location: st.lastKnownLocation.location,
                address: st.lastKnownLocation.address,
                contactPhone: d.supportPhone,
              },
              status: st.status,
            },
          ];
        });
      } else {
        this.api.viewTruckBranches(null).subscribe((d) => {
          this.branches = d && d.items;
        });
      }
    });

    this.expandedSections.workingHours = true;
  }

  callBranch(branch) {
    let link = document.createElement("a");
    link.href = "tel://" + branch?.location?.contactPhone;

    link.click();
    link.remove();
  }
  getDirection(branch) {
    var link = document.createElement("a");
    link.href =
      "https://www.google.com/maps/search/?api=1&query=" +
      branch?.location?.location?.lat +
      "," +
      branch?.location?.location?.lng;
    // link.target ="_blank";
    link.click();
    link.remove();
  }
}
