import { Component, OnInit } from "@angular/core";
import { ApiResult, LocalizedField } from "../models";
import { ApiService } from "../api.service";
import { UtilsService } from "../utils.service";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
  privacy: LocalizedField;
  isLoading = true;
  constructor(apiServce: ApiService, public utils: UtilsService) {
    apiServce.viewPrivacy().subscribe(
      (res) => {
        this.privacy = res;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  ngOnInit() {}
}
