import { Component, OnInit, ɵCompiler_compileModuleSync__POST_R3__, ViewEncapsulation, Output, Input } from '@angular/core';
import { ApiService } from '../api.service';

import { NgxSmartModalService } from 'ngx-smart-modal';
import { EventEmitter } from '@angular/core';
import * as _ from 'lodash'
import { CartService } from '../cart.service';
import { TruckBranch, LocalizedField } from '../models';
import { Cart } from '../cart';
import { Router } from '@angular/router';

@Component({
  selector: 'app-branch-selector',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './branch-selector.component.html',
  styleUrls: ['./branch-selector.component.scss']
})

export class BranchSelectorComponent implements OnInit {
  @Input()
  branch:number;
  @Input()
  notPickup:boolean = false;
  @Output()
  branchChange = new EventEmitter();
  @Output()
  branchesLoaded = new EventEmitter();
  isLoading:boolean = false
  closeResult: string;
  branches=[];
  searchText: string;
  _oldBranch:number
  results = [];
  get cart(): Cart{
    return this.cartService.getCart();
  }
  areaId: number;
  nextCallback:(branch:TruckBranch)=>void
  constructor(public modal:NgxSmartModalService, private apiService : ApiService, private cartService: CartService, private router: Router) { }

  selectBranch(cb?:(branch:TruckBranch)=>void) {
    this.isLoading = true;
    this.modal.getModal('mSelectBranch').open();  
    this.viewTruckBranches();  
    this.nextCallback = cb;
  }

  ngOnInit() {
   this.isLoading = true;
   this.viewTruckBranches();
   }

   viewTruckBranches(){
    this.branch = this.cart.orderInfo.branchId;
    this._oldBranch = this.branch;
    this.areaId = this.cart.orderInfo.pickupAddress.areaId; 
    this.apiService.viewTruckBranches(this.areaId).subscribe((data)=>{
      this.branches = data && data.items || [];
      if(!this.notPickup)
       this.branches = _.filter(this.branches, b=>_.some(b.services, s=>s == 'pickup'));
      this.branchesLoaded.emit(this.branches);
      this.isLoading = false; 
    }, err=>{
      this.isLoading = false;
    });
   }

  onSave(branch : TruckBranch){
    this.cartService.setBranch(branch);
    this.branchChange.emit(branch.id.toString());
    this.modal.getModal('mSelectBranch').close();
    if(branch && this._oldBranch != branch.id){
      this.apiService.initTruckInfo();
     // if(this.notPickup){ // not opened from pickup service 
        this.cartService.emptyCart(true); // empty cart with keeping area
        this.router.navigate(['/']);
      //}
    }
    if(this.nextCallback)
      this.nextCallback(this.cart.$branch);
    this.nextCallback = undefined;

  }

}

