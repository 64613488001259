<!-- <div class="row" style="height: 837px">
  <pdf-viewer
    [src]="'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'"
    [render-text]="true"
    [original-size]="false"
  >
  </pdf-viewer>
</div> -->
<div id="outerContainer">
  <div class="pdf-container">
    <pdf-viewer
      [src]="src"
      [rotation]="0"
      [original-size]="false"
      [show-all]="true"
      [fit-to-page]="false"
      [zoom]="1"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      style="width: 100%; height: 90vh"
    ></pdf-viewer>
  </div>
</div>

<div class="row" style="position: relative; display: grid">
  <button style="display: grid" (click)="download()" class="btn">
    <i class="fa fa-download"></i>
  </button>
</div>
