import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { UtilsService } from "../utils.service";
import { switchMap } from "rxjs/operators";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { CartService } from "../cart.service";
import { slideInUpOnEnterAnimation } from "angular-animations";

@Component({
  selector: "app-order-submitted",
  templateUrl: "./order-submitted.component.html",
  styleUrls: ["./order-submitted.component.scss"],
  animations: [slideInUpOnEnterAnimation({ delay: 1000 })],
})
export class OrderSubmittedComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public utils: UtilsService,
    private translate: TranslateService,
    private srvCart: CartService,
    private router: Router
  ) {}
  pageUrl: string;
  order: any;
  dateOnly: boolean = false;
  isLoading: boolean = false;
  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.isLoading = true;

          let id = params.get("id");
          let type = params.get("ot");
          if (!type) type = "Order";
          this.router.navigate(["/track-order"], {
            queryParams: { id: id, ot: type },
          });
          return;
          this.pageUrl = this.utils.getUrl("order/" + id);
          if (type == "GroupedOrder")
            return this.api.viewGroupedOrder(Number(id));
          else return this.api.viewOrder(Number(id));
        })
      )
      .subscribe((i) => {
        this.dateOnly = i.truck.useDateOnlyForPreOrder;
        this.order = _.cloneDeep(i);

        if (
          this.order.paymentMethod != "cashOnDelivery" &&
          this.order.status != "PendingPayment" &&
          this.order.status != "PaymentRejected"
        )
          this.srvCart.emptyCart();
        this.isLoading = false;
        this.utils.fbTrack("PageView");
      });
  }
  trackOrder() {}
}
