import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  TruckInfo,
  MenuItemInfo,
  MenuItem,
  Area,
  ApiResult,
  governate,
  DeliveryTime,
  TruckViewDto,
  CountryListDto,
  SearchResult,
  ShippingResult,
  ValidatePromoCodeResponse,
  ValidatePromoCodeCmd,
  SubmitOrderRes,
  TruckAboutUs,
  SBOptions,
  TruckSBOptions,
  CategoryInfo,
  CategoryView,
  LocalizedField,
  TruckArea,
  TruckSBOptionsWithDetails,
  TruckBranch,
  DeliveryTimeRes,
  MembershipInfo,
  TruckMenu,
  AppEventType,
  SocialIcons,
  TruckStatus,
} from "./models";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";
import * as _ from "lodash";
import { map, tap } from "rxjs/operators";
import { Cart } from "./cart";
import { HttpHeaders } from "@angular/common/http";
import { CartService } from "./cart.service";
import { Router } from "@angular/router";
import { env } from "process";
import * as uniqid from "uniquid";
import * as moment from "moment";

const authorizedHeaders = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Authorization:
      "access_token 3WUOvKcu10gY9YOpG6chSIf9A8qLLs+LCAAAAAQAAAAEAAAABAAAAAAAAAD4HQAAc2F3eQ==",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ApiService {
  _loadingMenu = false;
  _loadingOptions = false;
  _waitingTruck: Subscriber<TruckViewDto>[] = [];
  _waitingOptions: Subscriber<TruckSBOptionsWithDetails>[] = [];
  menuChanged: BehaviorSubject<TruckMenu> = new BehaviorSubject<TruckMenu>(
    null
  );
  menuChanging: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  initTruckInfo(areaId?: number) {
    this._waitingTruck = [];
    this._truck = null;
    this._loadingMenu = false;
    this._obTruck = new Observable((o) => {
      if (this._truck) o.next(this._truck);
      else {
        if (this._loadingMenu) {
          this._waitingTruck.push(o);
          return;
        }
        this._loadingMenu = true;
        this.menuChanging.emit(true);
        this._obOptions.subscribe((options) => {
          areaId = areaId || this.cart.getCart().orderInfo.pickupAddress.areaId;
          let branchId = this.cart.getCart().orderInfo.branchId;

          if (options.hasMultipleBranches) {
            if (areaId) {
              // reload menu based on branch
              if (options.simpleBrokerOptions.useMultiVendor)
                this.viewTruckBranches(areaId).subscribe((data) => {
                  // Get branches with delivery area Id
                  let branches = (data && data.items) || [];
                  let found = branches.find((b) => b.id == branchId);
                  if (branches.length >= 1 && !found) {
                    // not found selected branch in this area, set first one as default then empty cart,reload menu
                    this.cart.setBranch(branches[0]);
                    this.cart.emptyCart(true);
                    branchId = branches[0].id;
                    this._loadMenu(o, branchId);
                    this.router.navigate(["/"]);
                  } else if (branchId && (!found || !branches.length)) {
                    // with new area, not found last selected branch or branches not more than one set it to be undefined
                    this.cart.setBranch(undefined);
                    this.cart.emptyCart(true);
                    this._loadMenu(o);
                    this.router.navigate(["/"]);
                  } else if (branchId && found)
                    // found branch
                    this._loadMenu(o, branchId);
                  else this._loadMenu(o);
                });
              if (!options.simpleBrokerOptions.useMultiVendor || !branchId)
                this.getNearestBranch(areaId).subscribe(
                  (branch) => {
                    // get nearest branch of current area
                    if (branch)
                      this._loadMenu(o, branch.id > 0 ? branch.id : null);
                    else this._loadMenu(o);
                  },
                  (err) => {
                    this._loadMenu(o);
                  }
                );
            } else this._loadMenu(o);
          } else {
            this._loadMenu(o);
          }
        });
      }
    });

    this._obTruck.subscribe(() => {});

    return this._obTruck;
  }

  private _loadMenu(o: Subscriber<TruckViewDto>, branchId: number = null) {
    let urlEx = "";
    if (branchId) urlEx = `/for-branch/${branchId}`;

    let _self = this;
    this.http
      .get<TruckViewDto>(
        this._url(
          `/api/trucks/${environment.truckId}${urlEx}?withMenu=true&withMenuItems=true&withExtras=true&serviceType=delivery`
        )
      )
      .subscribe(
        (d) => {
          d.truck.menu = d.menu;
          d.menu.allCategories = _.flatten([
            d.menu.categories,
            ..._.concat(_.map(d.menu.categories, (m) => m.subCategories)),
          ]);
          d.menu.allItems = _.flatMap(d.menu.allCategories, (d) => d.items);
          if (_.includes(d.truck.services, "catering")) {
            d.menu.cateringItems = _.filter(d.menu.allItems, (d) =>
              _.includes(d.itemInfo.services, "catering")
            );
            d.menu.cateringCategory = {
              categoryInfo: {
                id: -100,
                title: {
                  ar: "الضيافة",
                  en: "Catering",
                },
              },
              items: d.menu.cateringItems,
              subCategories: [],
            };
          } else {
            d.menu.cateringItems = [];
          }
          if (d.menu.cateringItems && d.menu.cateringItems.length) {
            d.menu.allCategories = [
              d.menu.cateringCategory,
              ...d.menu.allCategories,
            ];
          }

          _.each(d.menu.allCategories, function (cat) {
            //cat.categoryInfo.extraDeliveryCharge = 1;

            _.each(cat.items, function (item) {
              item.catId = cat.categoryInfo.id;

              var foundItem = _.find(
                _self.cart.getCart().cartItems,
                function (cartItem) {
                  return cartItem.$item.id == item.itemInfo.id;
                }
              );
              item.qty = !foundItem ? 0 : foundItem.qty;
              return item;
            });
          });

          //environment.allow_about_us = d.truck.allowAboutUs;
          this.cart.setTruck(d.truck);
          this.cart.updateCart(d.menu);
          this._truck = d;
          o.next(d);
          _.each(this._waitingTruck, (s) => {
            s.next(d);
            s.complete();
          });
          o.complete();
          this._loadingMenu = false;
          this.menuChanged.next(d.menu);
          this.menuChanging.emit(false);
        },
        (e) => {
          this.menuChanging.emit(true);
          o.error(e);
        }
      );
  }
  private app_session: { sid: string; did: string; t: number };
  updateSession() {
    if (!this.app_session) return;
    if (moment().diff(moment(this.app_session.t), "minutes") < 2) return;
    this.app_session.t = new Date().getTime();
    localStorage.setItem("_se", JSON.stringify(this.app_session));
  }
  constructor(
    private http: HttpClient,
    private cart: CartService,
    private router: Router
  ) {
    let app_session: any = localStorage.getItem("_se");
    if (app_session && app_session.length)
      app_session = JSON.parse(app_session);
    if (!app_session) {
      //for the first time
      app_session = {
        sid: uniqid(),
        did: uniqid(), //generate a unique device id
        t: null,
      };
    }

    this.app_session = app_session;
    if (
      !app_session.t ||
      moment().diff(moment(app_session.t), "minutes") > 15
    ) {
      //15 minutes session
      app_session.sid = uniqid(); //generate a new unique id, for session, but keep the device id intact
      this.visitTruck(); //call visit truck, once this api is constructed
      this.trackEvent(AppEventType.OpenApp);
    }
    app_session.t = new Date().getTime();
    localStorage.setItem("_se", JSON.stringify(app_session));

    let areaId = this.cart.getCart().orderInfo.pickupAddress.areaId;

    this._obCountries = new Observable((o) => {
      if (this._countries) o.next(this._countries);
      else {
        this.http
          .get<SearchResult<CountryListDto>>(
            this._url(`/api/trucks/${environment.truckId}/countries`)
          )
          .subscribe(
            (d) => {
              if (d && d.items) this._countries = d.items;
              else this._countries = [];
              o.next(this._countries);
              o.complete();
            },
            (e) => o.error(e)
          );
      }
    });

    this._obOptions = new Observable((o) => {
      if (this._options) o.next(this._options);
      else {
        if (this._loadingOptions) {
          this._waitingOptions.push(o);
          return;
        }
        this._loadingOptions = true;
        this.http
          .get<TruckSBOptionsWithDetails>(
            this._url(
              "/api/trucks/" + environment.truckId + "/sb-options/details"
            )
          )
          .pipe(
            tap((d) => {
              if (d.simpleBrokerOptions && d.simpleBrokerOptions.socialLinks) {
                _.each(d.simpleBrokerOptions.socialLinks, (val, key) => {
                  if (val)
                    d.simpleBrokerOptions.socialLinks[key] = {
                      link: d.simpleBrokerOptions.socialLinks[key],
                      icon: SocialIcons[key],
                    };
                  else delete d.simpleBrokerOptions.socialLinks[key];
                });
              }
            })
          )
          .subscribe(
            (d) => {
              environment.default_country =
                (d.defaultCountry && d.defaultCountry.id) ||
                environment.default_country;
              this._loadingOptions = false;
              this._options = d;
              _.each(this._waitingOptions, (s) => {
                s.next(d);
                s.complete();
              });
              o.next(this._options);
              o.complete();
            },
            (err) => {
              o.error(err);
            }
          );
      }
    });

    //this.initTruckInfo();
  }

  private _obTruck: Observable<TruckViewDto>;
  private _obOptions: Observable<TruckSBOptionsWithDetails>;

  private _obCountries: Observable<CountryListDto[]>;

  private _truck: TruckViewDto;

  private _options: TruckSBOptionsWithDetails;
  private _countries: CountryListDto[];

  private _url(endpoint: string) {
    return environment.baseServiceUri + endpoint;
  }
  public _orderFromCart(cart: Cart) {
    let paymentMethod = cart.orderInfo.paymentMethod;
    let callBackUrl =
      location.protocol +
      "//" +
      location.hostname +
      (location.port ? ":" + location.port : "") +
      "/order/{0}";
    // if(paymentMethod == 'myfatoorah' && environment.card_payment)
    //   paymentMethod = environment.card_payment;

    //paymentMethod = 'myfatoorah';
    let pickupTime = null;
    let pickupTimeTo = null;
    if (cart.orderInfo.pickupTime) {
      try {
        pickupTime = new Date(cart.orderInfo.pickupTime).getTime();
      } catch {}
    }
    if (cart.orderInfo.pickupTimeTo) {
      try {
        pickupTimeTo = new Date(cart.orderInfo.pickupTimeTo).getTime();
      } catch {}
    }
    let expectedDeliveryTime = this.cart.expectedDeliveryTime
      ? new Date(this.cart.expectedDeliveryTime).getTime()
      : null;
    let expectedDeliveryTimeTo = this.cart.expectedDeliveryTimeTo
      ? new Date(this.cart.expectedDeliveryTimeTo).getTime()
      : null;
    let order = {
      orderInfo: {
        truckId: environment.truckId,
        paymentMethod: cart.orderInfo.paymentMethod,
        substitution: cart.orderInfo.substitution,
        callBackUrl: callBackUrl,
        membershipNo: cart.orderInfo.membershipNo,
        serviceType: cart.orderInfo.serviceType || "delivery",
        pickupAddress:
          cart.orderInfo.serviceType == "pickup"
            ? {
                areaId:
                  cart.$branch && cart.$branch.area && cart.$branch.area.id,
                contactPhone: cart.orderInfo.pickupAddress.contactPhone,
                contactName: cart.orderInfo.pickupAddress.contactName,
              }
            : cart.orderInfo.pickupAddress,
        pickupTime: pickupTime,
        pickupTimeTo: pickupTimeTo,
        expectedDeliveryTime: pickupTime ? null : expectedDeliveryTime,
        expectedDeliveryTimeTo: pickupTime ? null : expectedDeliveryTimeTo,
        branchId: cart.orderInfo.branchId,
        itemsTotal: cart.$subTotal,
        fileNumber: cart.orderInfo.fileNumber,
        promoCode: cart.orderInfo.promoCode,
        orderSource: "VendorWeb",
      },
      contactInfo: {
        firstName: cart.orderInfo.pickupAddress.contactName,
        phone: cart.orderInfo.pickupAddress.contactPhone,
      },
      cartItems: _.filter(
        _.map(cart.cartItems, function (ci) {
          return {
            itemId: ci.itemId || ci.$item.id,
            qty: ci.qty,
            unitPrice: ci.unitPrice || ci.$item.price,
            notes: ci.notes,
            extras: _.map(
              _.filter(ci.extras, (ei) => ei.$extraItem.qty > 0),
              function (ei) {
                return {
                  extraItemId: ei.id,
                  qty: ei.$extraItem.qty,
                  unitPrice: ei.price,
                };
              }
            ),
          };
        }),
        (ci) => ci.qty > 0
      ),
    };
    return order;
  }
  public visitTruck() {
    return this.http
      .put<any>(this._url(`/api/trucks/${environment.truckId}/visit`), null)
      .subscribe(
        (res) => {},
        (err) => {}
      );
  }
  public calculateShipping(cart: Cart): Observable<ShippingResult> {
    let order = this._orderFromCart(cart);
    return this.http.post<ShippingResult>(
      this._url("/api/orders/calculate-shipping"),
      order,
      authorizedHeaders
    );
  }
  public isBlockedNumber(number: Number): Observable<boolean> {
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };
    return this.http.get<boolean>(
      this._url(
        `/api/CustomerHistory/Customer/ValidateCustomer?phone=${number}`
      )
    );
  }
  public submitOrder(cart: Cart): Observable<SubmitOrderRes> {
    let order = this._orderFromCart(cart);
    if (!environment.production) {
    }
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };
    return this.http.post<SubmitOrderRes>(
      this._url("/api/customers/self/orders"),
      order,
      authHeaders
    );
  }

  public sendVerificationCoe(phone: any): Observable<any> {
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };
    return this.http.post<any>(
      this._url("/api/customers/send-verification-code"),
      phone,
      authHeaders
    );
  }

  public getCountries(): Observable<CountryListDto[]> {
    return this._obCountries;
  }
  public viewTruckAreas(): Observable<TruckArea[]> {
    return this.http.get<TruckArea[]>(
      this._url("/api/trucks/" + environment.truckId + "/areas")
    );
  }
  public viewTruckBranches(
    selectedAreaId
  ): Observable<SearchResult<TruckBranch>> {
    let qs = "";
    if (selectedAreaId) qs += "?criteria.areas=" + selectedAreaId;
    return this.http.get<SearchResult<TruckBranch>>(
      this._url("/api/trucks/" + environment.truckId + "/branches" + qs)
    );
  }
  public ViewTruckStatus(): Observable<TruckStatus> {
    let qs = "";
    return this.http.get<TruckStatus>(
      this._url("/api/trucks/" + environment.truckId + "/state" + qs)
    );
  }
  public viewTruck(): Observable<TruckViewDto> {
    return this._obTruck || this.initTruckInfo();
  }
  public viewAboutUs(): Observable<TruckAboutUs> {
    return this.http.get<TruckAboutUs>(
      this._url("/api/trucks/" + environment.truckId + "/about-us")
    );
  }
  public viewPrivacy(): Observable<LocalizedField> {
    return this.http.get<LocalizedField>(
      this._url("/api/sb/" + environment.truckId + "/privacy")
    );
  }
  public viewItem(id: number): Observable<MenuItem> {
    return new Observable((observable) => {
      this.viewTruck().subscribe((d) => {
        let item = _.find(d.menu.allItems, (i) => i.itemInfo.id == id);
        if (item) observable.next(item);
        else observable.error("not_found");
        observable.complete();
      });
    });
  }
  public viewCategory(id: number): Observable<CategoryView> {
    return new Observable((observable) => {
      this.viewTruck().subscribe((it) => {
        let allCategories = it.menu.allCategories;
        let res = _.find(
          allCategories,
          (i) => i.categoryInfo && i.categoryInfo.id == id
        );
        if (res) observable.next(res);
        else observable.error("not_found");
        observable.complete();
      });
    });
  }
  public redeemPromoCode(
    cmd: ValidatePromoCodeCmd
  ): Observable<ValidatePromoCodeResponse> {
    return this.http.post<ValidatePromoCodeResponse>(
      //this._url(`/api/promo-code/valid-state?truckId=${cmd.truckId}&paymentMethod=${cmd.paymentMethod}&promoCode=${cmd.promoCode}&itemsTotal=${cmd.itemsTotal}`));
      this._url(`/api/v2/promo-code/valid-state`),
      cmd
    );
  }
  // public getDeliveryTimes():Observable<DeliveryTime[]>{
  //   return new Observable((observable)=>{
  //     this.viewTruck().subscribe(d=>{
  //         observable.next(d.truck.deliveryTimes);
  //       observable.complete();
  //     })
  //   });
  // }

  // getAreas(): Observable<ApiResult<Area>> {
  //   return this.http.get<ApiResult<Area>>(this._url('/api/address/areas'));
  // }

  getGovernate(countryId?: number): Observable<ApiResult<governate>> {
    if (countryId && countryId != environment.default_country)
      return this.http.get<ApiResult<governate>>(
        this._url("/api/address/governates") + "?countryId=" + countryId
      );
    else
      return this.http.get<ApiResult<governate>>(
        this._url(
          "/api/address/governates/with-area?" +
            "countryId=" +
            environment.default_country
        )
      );
  }

  public getDeliveryStatus(orderNo: string): Observable<any> {
    return this.http.get<any>(
      this._url(
        "/api/trucks/self/orders/delivery-status?orderNo=" +
          (orderNo || "").trim() +
          "&truckId=" +
          environment.truckId
      ),
      authorizedHeaders
    );
  }

  public viewOrder(orderId): Observable<any> {
    return this.http.get<any>(
      this._url("/api/customers/self/orders/" + orderId),
      authorizedHeaders
    );
  }

  public viewGroupedOrder(orderId): Observable<any> {
    return this.http.get<any>(
      this._url(
        "/api/trucks/" + environment.truckId + "/grouped-orders/" + orderId
      ),
      authorizedHeaders
    );
  }
  public getDeliveryTimes(
    inRespectTo = new Date(),
    serviceType = "delivery",
    cartItems = []
  ): Observable<DeliveryTimeRes> {
    let qs = "";

    let reqObj = {
      inRespectTo: moment(inRespectTo).toDate().getTime(),
      serviceType,
      cartItems,
      areaId: this.cart.getCart().orderInfo.pickupAddress.areaId,
    };
    return this.http.post<DeliveryTimeRes>(
      this._url(
        `/api/v2/trucks/${environment.truckId}/delivery-times/available?v=${environment.app_version}${qs}`
      ),
      reqObj,
      authorizedHeaders
    );
  }

  public calculateDeliveryTime(options): Observable<any> {
    let qs = "truckId=" + environment.truckId;
    if (options.location) {
      qs += "&myLocation.lng=" + options.location.lng;
      qs += "&myLocation.lat=" + options.location.lat;
    }
    if (options.areaId) qs += "&areaId=" + options.areaId;

    return this.http.get<any>(
      this._url("/api/orders/calculate-delivery-time?" + qs),
      authorizedHeaders
    );
  }
  public viewSBOptions(): Observable<TruckSBOptions> {
    return this.http
      .get<TruckSBOptions>(
        this._url("/api/trucks/" + environment.truckId + "/sb-options")
      )
      .pipe(
        tap((d) => {
          if (d.simpleBrokerOptions && d.simpleBrokerOptions.socialLinks) {
            _.each(d.simpleBrokerOptions.socialLinks, (val, key) => {
              if (val)
                d.simpleBrokerOptions.socialLinks[key] = {
                  link: d.simpleBrokerOptions.socialLinks[key],
                  icon: SocialIcons[key],
                };
              else delete d.simpleBrokerOptions.socialLinks[key];
            });
          }
        })
      );
  }
  public getNearestBranch(areaId: number): Observable<TruckBranch> {
    return this.http.get<TruckBranch>(
      this._url(
        `/api/trucks/${environment.truckId}/areas/${areaId}/nearest-branch`
      )
    );
  }
  public getMembershipInfo(memberInfo: any): Observable<MembershipInfo> {
    let qs = "?t=1";
    if (memberInfo.civilId) qs += "&civilId=" + memberInfo.civilId;
    if (memberInfo.fileNumber) qs += "&fileNumber=" + memberInfo.fileNumber;
    if (memberInfo.membershipNo)
      qs += "&membershipNo=" + memberInfo.membershipNo;

    return this.http.get<MembershipInfo>(
      this._url(`/api/trucks/${environment.truckId}/membership` + qs)
    );
  }

  public viewSBOptionsDetails(): Observable<TruckSBOptionsWithDetails> {
    if (this._obOptions) return this._obOptions;

    return this._obOptions;
  }

  trackEvent(eventType: AppEventType, refId = null, metaData = undefined) {
    this.updateSession();
    let cmd = {
      appContextId: environment.truckId,
      eventType: eventType,
      refId: refId,
      source: "VendorWeb",
      sessionId: this.app_session.sid,
      deviceId: this.app_session.did,
      RawMetaData: metaData || {},
    };
    cmd.RawMetaData.domain = location.host;
    cmd.RawMetaData.truckId = environment.truckId;
    let cart = this.cart.getCart();
    if (cart && cart.orderInfo && cart.orderInfo.pickupAddress) {
      let add = cart.orderInfo.pickupAddress;
      if (add.contactName) cmd.RawMetaData.contractName = add.contactName;
      if (add.contactPhone) cmd.RawMetaData.contactPhone = add.contactPhone;
      if (add.areaName) cmd.RawMetaData.selectedArea = add.areaName;
      if (add.areaId) cmd.RawMetaData.selectedAreaId = add.areaId;
      if (add.countryId) cmd.RawMetaData.selectedCountryId = add.countryId;
    }
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };

    this.http
      .post(this._url(`/api/stats/app-event`), cmd, authHeaders)
      .subscribe((d) => {
        //do nothing
      });
  }

  public getTopSoldItems(): Observable<CategoryView> {
    return this.http.get<CategoryView>(
      this._url("/api/trucks/" + environment.truckId + "/orders/sold-items/top")
    );
  }

  public getTopRecentlyAddedItems(): Observable<CategoryView> {
    return this.http.get<CategoryView>(
      this._url("/api/trucks/" + environment.truckId + "/recently-items/top")
    );
  }

  // Registeration Module
  isAuthenticated = false;
  get isLoggedIn() {
    let u = this.getAuthUser();
    if (u && u.userId > 0) {
      return true;
    }
    return false;
  }
  public register(userData: any): Observable<any> {
    let data = {
      userName: userData.profile.email,
      password: userData.password,
      profile: userData.profile,
      loginInfo: {
        appKey: "customer.web",
        role: "customer",
        deviceInfo: {
          app: "vendor",
          deviceType: "browser",
        },
      },
      truckId: environment.truckId,
    };
    return this.http.post<any>(this._url(`/users/customer`), data);
  }

  public login(loginData: any): Observable<any> {
    let data = {
      userName: loginData.userName,
      password: loginData.password,
      appKey: "customer.web",
      role: "customer",
      deviceInfo: {
        app: "vendor",
        deviceType: "browser",
      },
      truckId: environment.truckId,
    };
    return this.http.post<any>(this._url(`/auth/login/password`), data);
  }

  public loginAsGuest(): Observable<any> {
    let data = {
      appKey: "customer.web",
      role: "customer",
      deviceInfo: {
        app: "vendor",
        deviceType: "browser",
      },
      truckId: environment.truckId,
    };
    return this.http.post<any>(this._url(`/auth/login/guest`), data);
  }

  public forgetPassword(email): Observable<any> {
    let data = {
      email: email,
      appKey: "customer.web",
      role: "customer",
      truckId: environment.truckId,
    };
    return this.http.post<any>(
      this._url(`/auth/login/password/forget-request`),
      data
    );
  }

  public resetPassword(resetData): Observable<any> {
    return this.http.post<any>(
      this._url(`/auth/login/password/reset`),
      resetData
    );
  }

  public logout(): Observable<any> {
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };
    return this.http.post<any>(this._url("/auth/logout"), {}, authHeaders);
  }

  public getAddresses(): Observable<any> {
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };
    return this.http.get<any>(
      this._url("/api/customers/self/address"),
      authHeaders
    );
  }

  public createAddress(addressData): Observable<any> {
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };
    let data = {
      areaId: addressData.areaId,
      address: addressData,
    };
    return this.http.post<any>(
      this._url("/api/customers/self/address"),
      data,
      authHeaders
    );
  }

  public updateAddress(addressId, addressData): Observable<any> {
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };
    let data = {
      areaId: addressData.areaId,
      address: addressData,
    };
    return this.http.put<any>(
      this._url("/api/customers/self/address/" + addressId),
      data,
      authHeaders
    );
  }

  public deleteAddress(addressId): Observable<any> {
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };
    return this.http.delete<any>(
      this._url("/api/customers/self/address/" + addressId),
      authHeaders
    );
  }

  public viewAddress(addressId): Observable<any> {
    return new Observable((observable) => {
      this.getAddresses().subscribe((res) => {
        let address = _.find(res, (ad) => ad.id == addressId);
        if (address) observable.next(address);
        else observable.error("not_found");
        observable.complete();
      });
    });
  }

  public getMyOrdrers(): Observable<any> {
    let authHeaders = {
      headers: new HttpHeaders({ Authorization: "" + this.getAccessToken() }),
    };
    if (this.getAuthUser().userId != -1)
      // check if its guest
      return this.http.get<any>(
        this._url("/api/customers/self/orders/"),
        authHeaders
      );
    else {
      let queryString =
        "?" +
        this.getAuthUser()
          .orderIds.map((id) => {
            return "orderIds=" + id;
          })
          .join("&");
      return this.http.get<any>(
        this._url("/api/guest/orders" + queryString),
        authHeaders
      );
    }
  }

  // Authentication
  public authUser(userData: any) {
    userData.orderIds = [];
    localStorage.setItem("user_data", JSON.stringify(userData));
    this.isAuthenticated = true;
  }

  public clearAuth() {
    localStorage.removeItem("user_data");
    this.isAuthenticated = false;
  }

  public getAuthUser(): any {
    let userStr = localStorage.getItem("user_data");
    if (userStr && userStr !== "undefined") return JSON.parse(userStr);
  }

  public addOrderIdToAuthUser(orderId) {
    let userData = this.getAuthUser();
    userData.orderIds.push(orderId);
    localStorage.setItem("user_data", JSON.stringify(userData));
  }
  private getAccessToken(): string {
    let authData = this.getAuthUser();
    if (authData && authData.accessToken)
      return "access_token " + authData.accessToken;
    else
      return "access_token 3WUOvKcu10gY9YOpG6chSIf9A8qLLs+LCAAAAAQAAAAEAAAABAAAAAAAAAD4HQAAc2F3eQ==";
  }

  public checkIfAuthenticated(): Observable<boolean> {
    return new Observable((observable) => {
      this._obOptions.subscribe((op) => {
        if (
          op.simpleBrokerOptions &&
          op.simpleBrokerOptions.enableRegistrationModule
        ) {
          let userData = this.getAuthUser();
          if (!userData) observable.next(false);
          else observable.next(true);
        } else observable.next(true);
        observable.complete();
      });
    });
  }
}
