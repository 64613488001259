import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from "@angular/core";
import { ApiService } from "../api.service";
import { UtilsService } from "../utils.service";
import { slideInUpOnEnterAnimation } from "angular-animations";
import { Address, GeoLocationDto, Area } from "../models";
import { CartService } from "../cart.service";
import * as _ from "lodash";
import { MapsAPILoader } from "@agm/core";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-map",
  templateUrl: "./select-location.component.html",
  styleUrls: ["./select-location.component.scss"],
  animations: [slideInUpOnEnterAnimation()],
})
export class SelectLocationComponent implements OnInit {
  pickupIcon: any = {
    icon: {
      url: "../../assets/images/markers/map-marker2.svg",
      scaledSize: { width: 41, height: 41 },
    },
  };
  @Output() areaChanged: EventEmitter<number> = new EventEmitter<number>();

  latitude: number;
  longitude: number;
  pickupAddress: Address;
  mapCenter: GeoLocationDto;
  mapZoom: number = 17;
  boundaries: any[];
  areaColor = "lightgreen";
  isGeocoding = false;
  areas: Area[];
  selected: Area;
  area: Area;
  isChanged: boolean = false;
  constructor(
    private srvCart: CartService,
    private api: ApiService,
    public utils: UtilsService,
    private mapApi: MapsAPILoader,
    private NgZone: NgZone,
    private toaster: ToastrService
  ) {
    this.pickupAddress = _.cloneDeep(
      this.srvCart.getCart().orderInfo.pickupAddress
    );
    this.mapCenter = _.clone(this.pickupAddress.location);
    this.api.getGovernate().subscribe((d) => {
      this.areas = _.flatMap(d.items, (d) => d.areas);
      this.selected = _.find(
        this.areas,
        (a) => a.id == this.pickupAddress.areaId
      );
      this.reloadMap(this.selected);
    });
  }

  private reloadMap(area) {
    if (area && area.boundaries) {
      let boundaries = JSON.parse(area.boundaries);
      if (boundaries && boundaries.length)
        this.boundaries = _.map(boundaries, (b) => {
          return {
            lat: b.latitude,
            lng: b.longitude,
          };
        });
      this.mapApi.load().then(() => {
        let latlng = new google.maps.LatLng(
          area.location.lat,
          area.location.lng
        );
        let poly = new google.maps.Polygon();
        poly.setPaths(
          this.boundaries.map((c) => new google.maps.LatLng(c.lat, c.lng))
        );
        this.latitude = area.location.lat;
        this.longitude = area.location.lng;
        // this.areaColor = google.maps.geometry.poly.containsLocation(latlng, poly) ? "lightgreen" : "red";
      });
    }
  }

  ngOnInit() {}
  setCurrentLocation() {
    this.latitude = this.pickupAddress.location.lat;
    this.longitude = this.pickupAddress.location.lng;

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapCenter = {
          lat: _.clone(position.coords.latitude),
          lng: _.clone(position.coords.longitude),
        };

        this.latitude = this.mapCenter.lat;
        this.longitude = this.mapCenter.lng;
        this.onCenterChanged(this.mapCenter);

        this.mapZoom = 7;

        this.decodeAddress();
      });
    }
  }
  onCenterChanged(center) {
    // this.latitude = center.lat;
    // this.longitude = center.lng;

    this.pickupAddress.location = {
      lat: center.lat,
      lng: center.lng,
    };
  }
  onMapIdle() {
    this.decodeAddress();
  }

  mapResults: any;
  decodeAddress() {
    this.mapApi.load().then((api) => {
      this.isGeocoding = true;
      let geocoder = new google.maps.Geocoder();
      let latlng = {
        lat: this.pickupAddress.location.lat,
        lng: this.pickupAddress.location.lng,
      };

      geocoder.geocode({ location: latlng }, (results) => {
        this.NgZone.run((d) => {
          this.utils.setAddressFromGeocoder(results, this.pickupAddress);

          this.mapResults = results;

          this.isGeocoding = false;
          //    if (this.boundaries) {

          //        let latlng = new google.maps.LatLng(this.pickupAddress.location.lat, this.pickupAddress.location.lng);
          //        let poly = new google.maps.Polygon();
          //        poly.setPaths(this.boundaries.map(c => new google.maps.LatLng(c.lat, c.lng)));
          //        //this.areaColor = google.maps.geometry.poly.containsLocation(

          //        //    latlng,
          //        //    poly
          //        //)
          //        //    ? "lightgreen"
          //        //    : "red";

          //    }
        });
      });
    });
  }

  save() {
    if (this.mapResults[1].address_components) {
      var tempArea = this.areas.find(
        (x) =>
          this.isAreaExist(this.mapResults[0].address_components, x.name.en) ||
          this.isAreaExist(this.mapResults[0].address_components, x.name.ar)
      );
      if (tempArea) {
        this.selected = tempArea;
        this.pickupAddress.areaId = tempArea.id;
        this.pickupAddress.areaName = tempArea.name;
        this.isChanged = true;
        //this.areaChanged.emit(tempArea.id);
      } else {
        this.toaster.warning(
          " Can't catch the area from map or This area not covered ",
          "Area Not Covered"
        );

        this.reloadMap(this.selected);
        return;
      }
    } else {
      return;
    }

    this.srvCart.setAddress(this.pickupAddress);
    this.utils.goBack();
  }

  isAreaExist(address_components: any[], areaName) {
    var areaIndex = address_components.findIndex(
      (x) => x.long_name == areaName
    );
    if (areaIndex > 0) {
      return true;
    } else {
      return false;
    }
  }

  onAreaSelected(area) {
    this.selected = area;
    this.pickupAddress.areaId = area.id;
    this.pickupAddress.areaName = area.name;

    this.isChanged = true;
    this.areaChanged.emit(area);
    this.reloadMap(area);
  }
}
