import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
} from "@angular/core";
import * as $ from "jquery";
@Directive({
  selector: "[iknLoader]",
})
export class IknLoaderDirective implements OnChanges {
  @Input()
  isActive: boolean;
  private _template: string =
    '<div class="lds-ellipsis" *ngIf="isValidatingPromoCode"><div></div><div></div><div></div><div></div></div>';
  constructor(private _el: ElementRef) {
    $(this._el.nativeElement).append($(this._template));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive) {
      if (changes.isActive.currentValue) {
        $(this._el.nativeElement).show();
      } else {
        $(this._el.nativeElement).hide();
        //$(this._el.nativeElement).find('.lds-ripple').remove()
      }
    }
  }
}
