import { Component, OnInit } from "@angular/core";
import { ApiService } from "../api.service";
import { ActivatedRoute } from "@angular/router";
import { UtilsService } from "../utils.service";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public utils: UtilsService
  ) {}
  successRes: boolean = false;
  notFoundRes: boolean = false;
  isLoading: boolean = false;
  orderNo: "";
  lat: number = 29.37612527749745;
  lng: number = 47.99124357866814;
  zoom: number = 12;
  deliveryState: any = { order: {} };
  state: any = { icon: "../../assets/images/markers/car-delivery-sm-blue.png" };
  truckLocation: any = { icon: "../../assets/images/resturant-marker.png" };
  pickupLocation: any = {
    icon: {
      url: "../../assets/images/pickup-sm.png",
      scaledSize: { width: 41, height: 41 },
    },
  };

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.orderNo = params["id"];

      this.trackOrder();
    });
  }
  trackOrder() {
    this.api.getDeliveryStatus(this.orderNo).subscribe(
      (res) => {
        this.deliveryState = res.deliveryState;

        this.setMapMarkers(res);
      },
      (err) => {}
    );
  }

  setMapMarkers(data) {
    let vehicleType = "car";
    if (data.deliveryState.driver)
      vehicleType =
        data.deliveryState.driver.carInfo.vehicleType == "Car" ? "car" : "bike";

    this.state.icon = {
      url:
        "../../assets/images/markers/" + vehicleType + "-delivery-sm-blue.png",
      scaledSize: {
        width: 41,
        height: 41,
      },
    };

    let tLocation = data.distanceToTruck.destination;
    let cLocation = data.distanceToCustomer.destination;
    let dLocation =
      data.distanceToTruck.origin && data.distanceToTruck.origin.lng
        ? data.distanceToTruck.origin
        : null;

    this.truckLocation.location = {
      id: -2,
      longitude: tLocation.lng,
      latitude: tLocation.lat,
    };

    this.pickupLocation.location = {
      id: -1,
      longitude: cLocation.lng,
      latitude: cLocation.lat,
    };

    if (dLocation) {
      this.state.location = {
        longitude: dLocation.lng,
        latitude: dLocation.lat,
      };
    }

    setTimeout(() => {
      this.trackOrder();
    }, 5000 * 60);
  }
}
