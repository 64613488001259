<!-- <div class="cat-big-card" (click)="onCategoryClick(cat)">
  <div class='d-flex flex-column c-pointer'>
    <div class="cat-img border rounded" [attr.style]="'background-image:url(' + (cat?.categoryInfo?.mainPic || cart?.$truck?.logoPic || cart?.$truck?.mainPic || 'assets/images/logo.png') + ')'"></div>
    <div class="cat-title">
      <h3>{{cat.categoryInfo.title | iknLocalized}}</h3>
    </div>
  </div>
</div> -->


<ng-container *ngIf="showItems">
  <div [ngClass]="{'last-category' : lastCategory == true}">
    <div class="card-top container  ">
      <h6 [ngClass]="cat?.items?.length > 0 ? '' :'mb-3'"> {{cat.categoryInfo.title | iknLocalized}} </h6>
    </div>


    <div class="elements">
      <ng-container *ngIf="cat?.items?.length > 0 && (!(cat?.subCategories?.length > 0))">
        <ng-container *ngFor="let item of cat?.items;let i = index">
          <div
            [ngStyle]="{'border-bottom': (i !=  cat?.items?.length -1) ? '2px solid #F0F0F0' : '2px solid  transparent' }"
            class=" ">
            <app-menu-item-card [item]="item"></app-menu-item-card>
          </div>
        </ng-container>

      </ng-container>

      <ng-container *ngIf="cat?.subCategories?.length > 0 && (!(cat?.items?.length > 0))">
        <ng-container *ngFor="let item of cat?.subCategories;let i = index">
          <app-base-category-card [lastCategory]="i == cat?.subCategories?.length -1" [showItems]="true"
            [category]="item"></app-base-category-card>
        </ng-container>
      </ng-container>
    </div>
  </div>

</ng-container>
<ng-container *ngIf="!showItems">

  <!-- <div (click)="onCategoryClick(cat)" class="card">
    <div class="card-body ">
      <p class="card-title"> {{cat.categoryInfo.title | iknLocalized}} </p>
      <div class="addcard">
        <h6> <span class="line-through"></span></h6>
        <button class="btn btn-outline-secondary px-3"> <i class="icon-clock"></i>
        </button>
      </div>
    </div>
    <img class="card-img-top cat"
      [src]="cat?.categoryInfo?.mainPic ||  cart?.$truck?.logoPic || cart?.$truck?.mainPic || 'assets/images/logo.png' "
      alt="{{cat.categoryInfo.title | iknLocalized}}">
  </div> -->
  <div class="cat-big-card" (click)="onCategoryClick(cat)">

    <img
      [src]="cat?.categoryInfo?.mainPic ||  cart?.$truck?.logoPic || cart?.$truck?.mainPic || 'assets/images/logo.png' "
      alt="{{cat.categoryInfo.title | iknLocalized}}">

    <h6 class="text-center">
      {{cat.categoryInfo.title | iknLocalized}}
    </h6>

  </div>


</ng-container>