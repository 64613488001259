import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitForm: FormGroup;
  submitted = false;
  isLoading:boolean = false;
  loginData = {
    userName: '',
    password: '',
  }
  redirectTo:string[] = null;

  logo: string = '';
  disableGuestOrdering:boolean = false;
  
  constructor(public utils:UtilsService, private router:Router, private api:ApiService, private translate: TranslateService,private formBuilder: FormBuilder,private route:ActivatedRoute, private notify: NotifyService) {
    this.route.params.subscribe(p=>{
      let redirectTo = p['returnTo'];
      if(redirectTo)
        this.redirectTo = [redirectTo];
    })
   }

  ngOnInit() {
    this.utils.fbTrack('Login');
    this.utils.snapchatTrack('LOGIN');
    //$('.page-side').removeClass('sm-hidden');
    this.submitForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
    });

    this.api.checkIfAuthenticated().subscribe(res => {
      if (res && this.api.getAuthUser().userId != -1)
        this.router.navigate(['/home']);
    });

    this.api.viewSBOptionsDetails().subscribe(op => {
      if(op.simpleBrokerOptions){
        this.logo = op.simpleBrokerOptions.logo || environment.logo;
        this.disableGuestOrdering = op.simpleBrokerOptions.enableRegistrationModule && op.simpleBrokerOptions.disableGuestOrdering;
      }
    });
  }

   // convenience getter for easy access to form fields
   get f() { return this.submitForm.controls; }

   login(){
    this.submitted = true;
     // stop the process here if form is invalid
    if (this.submitForm.invalid)
    return;

    this.isLoading = true;

    this.api.login(this.loginData).subscribe(res => {
      this.isLoading = false;
      this.api.authUser(res);
      this.redirectToUrl();
    }, err => {
      this.isLoading = false;
      this.notify.sweet_error(err); 
    });
  }

  loginAsGuest(){
    let userdata = this.api.getAuthUser();
    if(userdata && userdata.userId == -1){
      this.redirectToUrl();
      return;
    }

    this.isLoading = true;
    this.api.loginAsGuest().subscribe(res => {
      this.isLoading = false;
      this.api.authUser(res);
      this.redirectToUrl();
    }, err => {
      this.isLoading = false;
      this.notify.sweet_error(err); 
    });
  }

  redirectToUrl(){
    if(this.redirectTo && this.redirectTo.length)
        this.router.navigate(this.redirectTo);
      else
        this.utils.goBack();
  }
}
