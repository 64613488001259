import { Component, OnInit, OnDestroy } from '@angular/core';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { CartItem, CartExtraItem, TruckViewDto, TruckInfo, AppEventType, SBOptions, TruckSBOptionsWithDetails } from '../models';
import { UtilsService } from '../utils.service';
import * as _ from 'lodash'
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { fadeInOnEnterAnimation, lightSpeedInAnimation, lightSpeedInOnEnterAnimation, slideInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  animations: [slideInUpOnEnterAnimation({delay:1000}),fadeInOnEnterAnimation(), lightSpeedInOnEnterAnimation()]
})
export class OrderDetailComponent {

  isLoading:boolean = false;
  order:any;
  truck:TruckSBOptionsWithDetails
  constructor(private modal:NgxSmartModalService,
    public srvCart: CartService,
    private formBuilder:FormBuilder,
    private api : ApiService, public utils:UtilsService,
    private toaster:ToastrService,
    private route: ActivatedRoute,
    private router:Router,

    private trans:TranslateService) {


    this.route.params.subscribe(params => {
      let orderId = params['id'];
      this.isLoading = true;
      this.api.viewOrder(orderId).subscribe(o=>{
        this.order = o;
        this.isLoading = false;
      });
      this.api.viewSBOptionsDetails().subscribe(d=>{
        this.truck = d;
      });

    });
}


}
