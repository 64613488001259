import { Pipe, PipeTransform, LOCALE_ID, Inject } from "@angular/core";
import { DatePipe } from "@angular/common";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "iknMinutes",
  pure: false,
})
export class iknMinutesPipe implements PipeTransform {
  constructor(@Inject(TranslateService) private srvTrans: TranslateService) {}
  transform(time: any): any {
    if (!time) return "";
    let digits = Number(_.first((time as string).match(/\d+/g)));
    let postfix = _.lowerCase(_.first((time as string).match(/[mhdMHD]+/g)));
    let totalMinutes = 0;
    if (postfix == "h") totalMinutes = digits * 60;
    else if (postfix == "d") totalMinutes = digits * 60 * 24;
    else totalMinutes = digits;
    time = totalMinutes;
    let years = time / 365 / 24 / 60;
    let months = time / 30 / 24 / 60;
    let days = time / 24 / 60;

    let hours = (time / 60) % 24;
    let minutes = time % 60;

    let yearStr =
      years >= 1
        ? `${years.toFixed(0)} ${this.srvTrans.instant("Years")} `
        : "";
    let monthsStr =
      months >= 1
        ? `${months.toFixed(0)} ${this.srvTrans.instant("Months")} `
        : "";
    let daysStr =
      days >= 1 ? `${days.toFixed(0)} ${this.srvTrans.instant("Days")} ` : "";
    let hoursStr =
      hours >= 1
        ? `${hours.toFixed(0)} ${this.srvTrans.instant("Hours")} `
        : "";
    let minutesStr =
      minutes >= 1
        ? `${minutes.toFixed(0)} ${this.srvTrans.instant("Minutes")} `
        : "";

    return _.join(
      _.filter([yearStr, monthsStr, daysStr, hoursStr, minutesStr], (d) => d),
      this.srvTrans.instant("and")
    );
  }
}
