<div
  class="page-btn font-extraBold"
  *ngIf="cart?.cartItems?.length"
  routerLink="/cart"
>
  <a
    href="javascript:{}"
    class="btn btn-primary d-flex justify-content-between align-items-center"
  >
    <div>{{ cart?.$totalItemsQty }}</div>
    <div class="flex-grow-1 text-center">{{ "Review Cart" | translate }}</div>
    <div>
      {{ cart?.$subTotal | iknCurrency }}
      {{ cart?.$truck?.defaultCountry?.currencyCode | iknLocalized }}
    </div>
  </a>
</div>
<app-top-header
  class="top-bar-container"
  [isCategoryItemsScreen]="true"
  [bgStyle]="'solid'"
  [screenTitle]="category?.categoryInfo?.title | iknLocalized"
></app-top-header>
<!-- <div class="cat-clearfix">
    &nbsp;
</div> -->

<!-- <div class="page-content">
    <div iknLoader [isActive]='!truck' class="loader-container"></div>
    <div *ngFor="let item of items | filter: searchText">
        <div>
            <app-menu-item-card [item]="item"></app-menu-item-card>
        </div>
    </div>
</div> -->
<app-extra-category-alert
  [(deliveryCharge)]="category?.categoryInfo.extraDeliveryCharge"
  [(deliveryTime)]="category?.categoryInfo.extraDeliveryTimeMinutes"
  class="pull-heading-right"
>
</app-extra-category-alert>
<!-- <app-footer></app-footer>
<br />
<br /> -->

<!-- <div class="card-column">
    <div class="menu-card">
    <a routerLink='/item/{{item?.itemInfo.id}}'>
      <div class="img"><img [src]="item?.itemInfo?.mainPic || 'assets/images/logo.png'" alt=""></div>
      <div class="card-title f-s-18 text-center">{{item?.itemInfo?.title | iknLocalized}}</div>
    </a>
    <div class="row-nums">
      <div class="desc text-danger out-stock-txt respect-dir" *ngIf="checkOutOfStock(item)">
        {{'Out of Stock'| translate}}
      </div>
      <div class="force-rtl" style="display:flex;" *ngIf="!checkOutOfStock(item)">
        <span class="bg-primary plus-icon" (click)="addItem(item)"><i class="im im-plus"></i></span>
        <span class="num-qty respect-dir">
          <span class="card-price font-bold">{{item.itemInfo.price | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}</span>
          <span class="card-qty">&nbsp;<i class="fa fa-times"></i> {{item.qty}}</span>
        </span>
        <span class="bg-primary minus-icon" (click)="removeItem(item)"><i class="im im-minus"></i></span>
      </div>
    </div>
  </div>
  
  </div> -->

<section class="categories-sec pt-2">
  <div class="container p-0 m-0">
    <div class="row cards-sec p-0 m-0 list-mode">
      <div iknLoader [isActive]="!truck" class="loader-container"></div>
      <ng-container *ngIf="items?.length > 0">
        <ng-container *ngFor="let item of items | filter : searchText">
          <div class="col-6 col-card px-0 pb-2 mb-0">
            <app-menu-item-card [item]="item"></app-menu-item-card>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>
