<!-- <div class="card-column">
  <div class="menu-card">
  <a routerLink='/item/{{item?.itemInfo.id}}'>
    <div class="img"><img [src]="item?.itemInfo?.mainPic || 'assets/images/logo.png'" alt=""></div>
    <div class="card-title f-s-18 text-center">{{item?.itemInfo?.title | iknLocalized}}</div>
  </a>
  <div class="row-nums">
    <div class="desc text-danger out-stock-txt respect-dir" *ngIf="checkOutOfStock(item)">
      {{'Out of Stock'| translate}}
    </div>
    <div class="force-rtl" style="display:flex;" *ngIf="!checkOutOfStock(item)">
      <span class="bg-primary plus-icon" (click)="addItem(item)"><i class="im im-plus"></i></span>
      <span class="num-qty respect-dir">
        <span class="card-price font-bold">{{item.itemInfo.price | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}</span>
        <span class="card-qty">&nbsp;<i class="fa fa-times"></i> {{item.qty}}</span>
      </span>
      <span class="bg-primary minus-icon" (click)="removeItem(item)"><i class="im im-minus"></i></span>
    </div>
  </div>
</div>

</div> -->

<div class="card p-3">
  <div class="card-body">
    <p (click)="openItemDetails(item)" class="card-title font-bold">
      {{ item?.itemInfo?.title | iknLocalized }}
    </p>
    <p (click)="openItemDetails(item)">
      {{ item?.itemInfo?.ingredients | iknLocalized }}
    </p>
    <!-- <div>
      <span class="card-qty">
        {{item.qty}}</span>

    </div> -->
    <div *ngIf="!checkOutOfStock(item)" class="addcard">
      <h6 *ngIf="isAddon(item)">
        <ng-container *ngIf="item?.itemInfo?.price > 0">
          <span
            >{{ item.itemInfo.price.toFixed(3) }}
            {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
          </span>
          <span
            *ngIf="
              item?.itemInfo?.priceBefore != null &&
              item?.itemInfo?.priceBefore > 0
            "
            class="line-through"
          >
            {{ item.itemInfo.priceBefore | iknCurrency }}
            {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
          </span>
        </ng-container>

        <ng-container *ngIf="!(item?.itemInfo?.price > 0)">
          <span>{{ "Price on selection" | translate }} </span>
        </ng-container>
      </h6>
      <h6 *ngIf="!isAddon(item)" class="card-price font-bold">
        <span
          >{{ item.itemInfo.price.toFixed(3) }}
          {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
        </span>
        <span
          *ngIf="
            item?.itemInfo?.priceBefore != null &&
            item?.itemInfo?.priceBefore > 0
          "
          class="line-through"
        >
          {{ item.itemInfo.priceBefore | iknCurrency }}
          {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
        </span>
      </h6>
      <button
        *ngIf="isAddon(item)"
        (click)="openItemDetails(item)"
        class="btn btn-outline-secondary"
      >
        <i class="fa fa-plus px-1"></i>
        <span class="px-1">
          {{ "Add" | translate }}
        </span>
      </button>
      <button
        *ngIf="!isAddon(item)"
        (click)="addItem(item)"
        class="btn btn-outline-secondary"
      >
        <i class="fa fa-plus px-1"></i>
        <span class="px-1">
          {{ "Add" | translate }}
        </span>
      </button>
    </div>
    <div class="addcard" *ngIf="checkOutOfStock(item)">
      <h6 class="text-danger">{{ "Out of Stock" | translate }}</h6>
    </div>
  </div>
  <img
    (click)="openItemDetails(item)"
    class="card-img-top"
    [src]="item?.itemInfo?.mainPic || 'assets/images/logo.png'"
    alt="{{ item?.itemInfo?.title | iknLocalized }}"
  />
</div>
