<div
  [routerLink]="['/cart']"
  class="position-relative color-white t-medium cursor-pointer"
>
  <!-- <i class="im im-cart"></i> -->
  <div
    *ngIf="getCart().cartItems.length"
    class="position-absolute d-flex align-items-center justify-content-between"
    style="top: 0; left: 0; right: 0; bottom: 0"
  >
    <div class="m-auto pt-1">
      {{ getCart().$totalItemsQty }}
    </div>
  </div>
  <i class="fal fa-shopping-bag fa-2x"></i>
</div>
